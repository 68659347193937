import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import Overview from 'component/report-siswa/dashboard'
import Kelas from 'component/report-siswa/sub-mata-pelajaran'
import Scores from 'component/scores'
import Badge from './badge'

export default function ProgressSiswa() {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()

  /* ======================================= Local States ======================================= */
  const [links, setLinks] = useState([])
  const [siswa, setSiswa] = useState(null)

  /* ========================================= Functions ======================================== */
  function checkRole() {
    const { role_type } = JSON.parse(localStorage.getItem('role_active'))

    if (role_type === 'orangtua') {
      const { siswa } = location.state
      setLinks([{ url: '/siswa', name: 'Siswa' }])
      setSiswa(siswa)
    } else if (role_type === 'siswa') {
      setLinks([])
      setSiswa({ name: 'Progress Siswa' })
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    checkRole()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName={siswa?.name || ''} links={links} />
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-pills bg-white"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active text-uppercase"
                      data-toggle="tab"
                      href="#contents"
                      role="tab"
                      aria-controls="user1"
                      aria-selected="true"
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#users"
                      role="tab"
                      aria-controls="user2"
                      aria-selected="false"
                    >
                      Sub Mata Pelajaran
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#rules"
                      role="tab"
                      aria-controls="user4"
                      aria-selected="false"
                    >
                      Badges
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#rekap"
                      role="tab"
                      aria-controls="user4"
                      aria-selected="false"
                    >
                      Rekap Nilai
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="contents"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <Overview />
              </div>
              <div
                className="tab-pane fade show"
                id="users"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <Kelas />
              </div>
              <div
                className="tab-pane fade show"
                id="rules"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <Badge />
              </div>
              <div
                className="tab-pane fade show"
                id="rekap"
                role="tabpanel"
                aria-labelledby="user1-tab"
              >
                <Scores />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}
