import React, { useEffect, useMemo, useState } from 'react'
import Answer from './answer'
import './question.css'
import GraphorSort from './GraphorsSort'
import GraphorGroup from './GraphorsGroup'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { editorConfiguration } from 'util/ckeditor-config'
import CkeditorReadonly from 'component/ckeditor-readonly'
import BaseButton from 'component/button/BaseButton'

const Question = ({
  question,
  handleChangePilihanGanda,
  handleAnswerText,
  handleChangeGraphors,
  questionIndex,
  answerList = [],
  readOnly = false,
  isPreview,
  resendAnswerHandler,
}) => {
  const answerFind = answerList?.[questionIndex]

  /* ====================================== Consume Context ===================================== */

  /* ======================================= Local States ======================================= */
  /* Graphor State */
  const [dg, setDG] = useState(null)
  const [answers, setAnswers] = useState([])

  /*Type Sort*/
  const [list, setList] = useState([])

  /*Type Group*/
  const [group, setGroup] = useState([])
  const [listGroup, setListGroup] = useState(null)
  /* End of Graphor State */

  /* ========================================= Functions ======================================== */
  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  function initTypeSort(array) {
    let dt = []
    let filterAnswer = answerList.filter(
      (item) => item?.question_id == question?.id && item?.answer_text,
    )
    let newList = []
    let s = shuffle(array)
    s.map((val) => {
      dt.push({
        text: val,
      })
    })
    setAnswers(() => dt)
    if (filterAnswer.length > 0) {
      newList =
        typeof filterAnswer[0].answer_text === 'string'
          ? JSON.parse(filterAnswer[0].answer_text)
          : filterAnswer[0].answer_text
    } else {
      newList = dt.map((val) => val.text)
    }
    setList(newList)
  }

  function initTypeGroup(column) {
    let groupName = []
    var list = []
    let filterAnswer = answerList.filter(
      (item) => item?.question_id == question?.id && item?.answer_text,
    )
    column &&
      Object.keys(column).forEach((grup) => {
        if (column[grup].length > 0) {
          for (var a = 0; a < column[grup].length; a++) {
            list.push(column[grup][a])
          }
          column[grup] = []
        }
        if (grup != 'list') {
          groupName.push(grup)
        }
      })
    if (filterAnswer.length > 0) {
      column =
        typeof filterAnswer[0].answer_text === 'string'
          ? JSON.parse(filterAnswer[0].answer_text)
          : filterAnswer[0].answer_text
    } else {
      var randomList = shuffle(list)
      column['list'] = randomList
    }
    setListGroup(column)
    setGroup(groupName)
  }

  function initGraphor() {
    let response = question
    if (response) {
      const graphor_answers = response?.graphor_answers
      setDG(graphor_answers?.data)
      if (graphor_answers?.data?.graphor_type == 1) {
        initTypeSort(graphor_answers?.answer)
      } else if (graphor_answers?.data?.graphor_type == 2) {
        initTypeGroup(graphor_answers?.column)
      }
    }
  }

  const GraphorQuestion = useMemo(() => {
  // const GraphorQuestion = () => {
    if (dg !== null) {
      if (dg?.graphor_type == 1 && answers.length > 0) {
        return (
          <GraphorSort
            questions={question}
            handleChangeGraphors={handleChangeGraphors}
            list={list}
            setList={setList}
          />
        )
      } else if (dg?.graphor_type == 2) {
      return (
          <GraphorGroup
            questions={question}
            handleChangeGraphors={handleChangeGraphors}
            group={group}
            listGroup={listGroup}
            setListGroup={setListGroup}
          />
        )
      } else {
        return null
      }
    } else {
      return null
    }
  // }
  },
  [question, handleChangeGraphors, group, listGroup, setListGroup, dg, list, setList, answers, setGroup]
  )

  const PGQuestion = useMemo(() => {
    if (Array.isArray(question?.answers) && question?.answers?.length) {
      return question?.answers?.map((answer) => (
        <Answer
          key={answer?.id}
          answer={answer}
          handleChangeAnswer={() => handleChangePilihanGanda(answer)}
          // handleChangeAnswer={() => {}}
          answerList={answerList}
          answerFind={answerFind?.answer_id}
          readOnly={readOnly}
        />
      ))
    }
  },
  [handleChangePilihanGanda, readOnly,  answerList, question, answerFind]
  )

  const EssayQuestion = useMemo(() => {
    return <CKEditor
      editor={Editor}
      data={answerFind?.answer_text ? answerFind.answer_text : ''}
      config={editorConfiguration}
      onBlur={(_, editor) => {
        const data = editor?.getData()
        handleAnswerText(data, questionIndex)
      }}
    />
  },
  [question, handleAnswerText, editorConfiguration, answerFind, Editor, isPreview]
  )

  useEffect(() => {
    if (question?.quest_type === 2) {
      initGraphor()
    }
  }, [question])

  const isQuestionSuccessSavedBoolean = question?.hasOwnProperty('isSuccessSaved') && typeof question?.isSuccessSaved === 'boolean'
  const backgroundColor = isQuestionSuccessSavedBoolean && question?.isSuccessSaved === false ? 'bg-danger-light' : 'bg-light'

  /* ========================================== Output ========================================== */
  return (
    <li
      style={{ position: 'relative', overflow: 'hidden' }}
      className={`mb-4 border rounded px-3 pb-3 pt-5 ${backgroundColor}`}
    >
      <span
        className="bg-primary text-light"
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          padding: '5px',
          borderBottomRightRadius: '8.5px',
          // borderTopLeftRadius: '8.5px'
        }}
      >
        <span className="mr-1 f-w-500">Pertanyaan</span>
        <span className="f-w-500">{questionIndex + 1}</span>
        {/* <span className='f-w-500'>{question ? question.urutan : "0"}</span> */}
      </span>
      <div className="">
        <CkeditorReadonly data={question.text} />
      </div>
      {question ? (
        <>
          {question.quest_type === 0 ? (
            <ul className="list-unstyled ml-2">
              {question.answers.length !== 0
                ? PGQuestion
                : null}
            </ul>
          ) : null}
          {question.quest_type === 1 ? (
            <>
              {readOnly ? (
                <div className="form-group ml-2 border rounded p-2 bg-white">
                  <span className="f-w-700">Jawaban saya: </span>
                  <CkeditorReadonly
                    data={answerFind?.answer_text ? answerFind.answer_text : ''}
                    id={`${questionIndex}-${question.id}`}
                  />
                </div>
              ) : (
                <div className="form-group ml-3">
                  {isPreview ?
                  <input type="text" className='form-control' />
                :
                  EssayQuestion
                }
                </div>
              )}
            </>
          ) : null}
          {question.quest_type === 2 ? (
            <div className="form-group mb-0">
              {GraphorQuestion}
              {/* <GraphorQuestion /> */}
            </div>
          ) : null}
        </>
      ) : null}

      {isQuestionSuccessSavedBoolean && question?.isSuccessSaved === true
      ?
        <span
        className='position-absolute bg-success text-white px-3 py-2'
        style={{ top: 0, right: 0, borderBottomLeftRadius: 8.5 }}
        >Tersimpan</span>
      : isQuestionSuccessSavedBoolean && question?.isSuccessSaved === false
      ?
      <BaseButton 
        variant='warning'
        props={{ className: 'button-resend-answer' }}
        isMarginRight={false}
        onClick={() => resendAnswerHandler(question)}
      >
        <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
        
        &nbsp;
        Tidak Tersimpan! Silahkan Kirim Ulang Jawaban
      </BaseButton>
      : null}
    </li>
  )
}

export default Question
