import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import DataTable from 'component/data-table'

import { createQueryUrl } from 'util/string'
import ModalDetailPeriksa from './components/ModalDetailPeriksa'
import UserItem from './components/UserItem'
import { listKeteranganTabPeriksa } from 'data/rekapNilai'
import BaseButtonInformationModal from 'component/button/BaseButtonInformationModal'

const GlobalContentDashboardPeriksa = () => {
  /* ========================================== Helpers ========================================= */
  const params = useParams()
  const kelasId = params.kelasId

  /* =========================================== Refs =========================================== */
  const isMounted = useRef(true)

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  const { content } = useContentPlayerContext()

  const initSelectedGroup = () => {
    return content?.selectedStudent?.selectedGroup || { value: null, label: 'Pilih Kelas' }
  }

  /* ======================================= Local States ======================================= */
  const [assignmentUsers, setAssignmentUsers]     = useState([])
  const [grupKecilData, setGrupKecilData]         = useState(null)
  const [loading, setLoading]                     = useState(false)
  const [totalRows, setTotalRows]                 = useState(0)
  const [limit, setLimit]                         = useState(10)
  const [offset, setOffset]                       = useState(0)
  const [keyword, setKeyword]                     = useState('')
  const [groups, setGroups]                       = useState([])
  const [group, setGroup]                         = useState(initSelectedGroup)
  const [selectedUser, setSelectedUser] = useState(null)
  const [isShowModalDetailPeriksa, setIsShowModalDetailPeriksa] = useState(false)

  /* ========================================= Functions ======================================== */
  const fetchGrupKecilHandler = async () => {
    const response = await getRequest(`forum-group-teacher?content_id=${content.id}`)
    setGrupKecilData(response?.data)

    return response
  }

  async function getAllUsers() {
    const group_id = group?.value === 'all' ? null : group?.value
    if (group?.value !== null) {
      const queryContentId = createQueryUrl({ queryName: 'content_id', value: content?.id, seperator: '?' })
      const queryKeyword = createQueryUrl({ queryName: 'search', value: keyword })
      const queryGroupId = createQueryUrl({ queryName: 'group_id', value: group_id })
      const queryClassId = createQueryUrl({ queryName: 'class_id', value: kelasId })
  
      const response = await getRequest(
        `content-score-detail${queryContentId}${queryKeyword}${queryGroupId}${queryClassId}&offset=${offset}&limit=${limit}`,
      )
      const fetchGrupKecil = await fetchGrupKecilHandler()
  
      setLoading(false)
      if (response && fetchGrupKecil) {
        setAssignmentUsers(response.data)
        setTotalRows(response.totalRows)
      }
    }
  }

  function getPrasyarat(selectedUser) {
    const participantsId =
      [
        ...new Set(
          (grupKecilData || selectedUser?.grupKecilData)?.reduce((arr, next) => {
            next.forums &&
              next.forums.forEach((_next) => {
                _next.forums &&
                  _next.forums.forEach((__next) => {
                    arr.push(__next.login.user_id)
                  })
                arr.push(_next.login.user_id)
              })
            return arr
          }, []),
        ),
      ] || []

    const isParticipated = participantsId.some((uid) => uid === selectedUser.id)
    const feedback = content.feed?.feed_temps.filter(
      (item) =>
        item.content_id == content.id &&
        item.user_id == selectedUser.id,
    )

    const _isFeedbackCompleted = feedback
    const _isGrupKecilRequired = content.group_status
    const _isGrupKecilPassed = !_isGrupKecilRequired || isParticipated

    return {
      _isGrupKecilPassed,
      _isFeedbackCompleted,
    }
  }

  const getDetailDataAndShowDetail = useCallback(async () => {
    if (content?.urlQueryFetch) {
      const fetchGrupKecil = await fetchGrupKecilHandler()

      if (fetchGrupKecil) {
        const data = {
          ...content?.selectedStudent,
          grupKecilData: fetchGrupKecil?.data
        }
        onDetailHandler(data)
      }
    }
  }, [content?.urlQueryFetch]);

  async function getGroups() {
    const response = await getRequest(
      `group-by-mapel?class_id=${kelasId}`,
    )
    if (response) {
      if (response.data.length !== 0) {
        const groups = response.data.map((group) => ({
          value: group?.id,
          label: group.group,
        }))
        setGroups([{ value: 'all', label: 'Semua Kelas' }, ...groups])
      }
    }
  }

  const onDetailHandler = (item) => {
    const prasyarat = getPrasyarat(item)

    setSelectedUser({
      ...prasyarat,
      ...item,
    })
    setIsShowModalDetailPeriksa(true)
  }

  function onCloseModalDetailPeriksaHandler() {
    setSelectedUser(null)
    setIsShowModalDetailPeriksa(false)
  }

  useEffect(() => { 
    getDetailDataAndShowDetail()
  }, [])
  /* ======================================== Components ======================================== */


  const UserGroupPicker = () => (
    <>
      <h5>Pilih Kelas: </h5>
      <Select
        placeholder="Kelas"
        options={groups}
        onChange={(item) => setGroup(item)}
        className="mb-3"
        defaultValue={group}
      />
    </>
  )

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    setOffset(0)
  }, [keyword, limit, group])

  useEffect(() => {
    if (isMounted.current && group) {
      setLoading(true)
      getAllUsers()
    }
  }, [offset, keyword, limit, group])

  useEffect(() => {
    getGroups()
  }, [])

  /* ======================================== Constants ======================================= */
  const datatableColumn = ['Nama', 'No. Induk', 'Nilai', 'Actions']
  if (
    content.feedback ||
    selectedUser?.grup
  )
    datatableColumn.push('Prasyarat')

  return (
    <div className="dt-responsive table-responsive">
      <div className='row align-items-center'>
        <div className='col-sm-4'>
          <UserGroupPicker />
        </div>
        <div className='col-sm-4 offset-sm-4 d-flex align-items-center justify-content-end'>
          <BaseButtonInformationModal
            list={listKeteranganTabPeriksa}
          />
        </div>
      </div>
      {(content.feedback || content.group_status) && (
        <div
          className="mt-2 border rounded alert alert-warning w-100"
          role="alert"
        >
          <strong>
            <i className="fas fa-info-circle mr-1" />
            Siswa wajib
            {content.feedback && ` memberikan feedback`}
            {content.feedback && content.group_status && ` dan`}
            {content.group_status && ` terlibat dalam diskusi kecil`} pada
            materi.
          </strong>
        </div>
      )}
      <div className='position-absolute'>
        
      </div>
      <DataTable
        className={'table table-striped table-bordered nowrap'}
        data={assignmentUsers}
        totalRows={totalRows}
        show={[10, 20, 30]}
        onChangeSearch={(val) => setKeyword(val)}
        onChangeLimit={(val) => setLimit(val)}
        onChangePage={(val) => setOffset(val)}
        column={datatableColumn}
        renderItem={(val, index) => (
          <UserItem
            item={val}
            key={index}
            detailHandler={() => onDetailHandler(val)}
          />
        )}
      />

      <ModalDetailPeriksa 
        selectedUser={selectedUser} 
        show={isShowModalDetailPeriksa} 
        contentId={content.id}
        handleCloseModal={onCloseModalDetailPeriksaHandler} 
        getAllUsers={getAllUsers}
        content={content}
      />
    </div>
  )
}

export default GlobalContentDashboardPeriksa
