import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom'
import DataTable from 'pages/dummy/DataTable'
import LandingPage from 'pages/landing-page'
import Admin from 'pages'
import Dashboard from 'pages/dashboard'
import { useBaseContext } from 'context/base'

import { debug } from 'util/host'

// Menus
import Menu from 'pages/menus'
import MenuAdd from 'pages/menus/add'
import MenuTable from 'pages/menus/menu-table'

// Profile
import Profile from 'pages/profile'

// Users
import Users from 'pages/users'
import AddUsers from 'pages/users/add'
import EditUsers from 'pages/users/edit'
import ManageSiswa from 'pages/users/manage-siswa'
import AddProfile from 'pages/users/add-profile'

// Auth
import Login from 'pages/auth/login'
import SignUp from 'pages/auth/sign-up'
import Reset from 'pages/auth/reset'
import NewPassword from 'pages/auth/new-password'
import SetToken from 'pages/auth/set-token'
import DefaultNoAuth from 'pages/auth/default-no-auth'

// Roles
import RolesAdd from 'pages/roles/add'
import Roles from 'pages/roles'
import RolesEdit from 'pages/roles/edit'
import RoleHakAkses from 'pages/roles/hak-akses'

// Group
import Group from 'pages/group'
import GroupAdd from 'pages/group/Add'
import GroupDetail from 'pages/group/Detail'

// Gamifikasi
import Gamification from 'pages/gamification'
import CostumizeBadges from 'pages/gamification/badges'

// Kelas Kategori
import KelasKategori from 'pages/kelas-kategori'
import KelasKategoriAdd from 'pages/kelas-kategori/add'
import KelasKategoriEdit from 'pages/kelas-kategori/edit'

// Basic Profile
import BasicProfile from 'pages/basic-profile'
import ProfileSuperAdmin from 'pages/profile-super-admin'
import AddProfileSuperAdmin from 'pages/profile-super-admin/add'

import ProfileSettingUsers from 'pages/profile-users'

import Chat from 'pages/chat'

import Kalender from 'pages/kalender'

import KelasOverview from 'pages/kelas/overview'

// 404
import NotFound from 'pages/404'

// Kelas
import Kelas from 'pages/kelas'
import KelasAdd from 'pages/kelas/add'
import KelasEdit from 'pages/kelas/edit'

// Kelas Overview Content
import AddContentText from 'pages/kelas/contents-add/add-content-text'
import AddContentVideo from 'pages/kelas/contents-add/add-content-video'
import AddContentAudio from 'pages/kelas/contents-add/add-content-audio'
import AddContentDocument from 'pages/kelas/contents-add/add-content-document'
import AddContentIframe from 'pages/kelas/contents-add/add-content-iframe'
import AddContentAssignment from 'pages/kelas/contents-add/add-content-assignment'
import AddContentQuiz from 'pages/kelas/contents-add/add-content-quiz'
import AddContentSurvey from 'pages/kelas/contents-add/add-content-survey'
import AddContentGraphor from 'pages/kelas/contents-add/add-content-graphor'
import AddContentWhiteboard from 'pages/kelas/contents-add/add-content-whiteboard'
import AddSectionKelas from 'pages/kelas/contents-add/add-section'
import EditSectionKelas from 'pages/kelas/contents-edit/edit-section'
import EditContentText from 'pages/kelas/contents-edit/edit-content-text'
import EditContentAudio from 'pages/kelas/contents-edit/edit-content-audio'
import EditContentVideo from 'pages/kelas/contents-edit/edit-content-video'
import EditContentDocument from 'pages/kelas/contents-edit/edit-content-document'
import EditContentIframe from 'pages/kelas/contents-edit/edit-content-iframe'
import EditContentQuiz from 'pages/kelas/contents-edit/edit-content-quiz'
import EditContentSurvey from 'pages/kelas/contents-edit/edit-content-survey'
import EditContentAssignment from 'pages/kelas/contents-edit/edit-content-assignment'
import EditContentGraphor from 'pages/kelas/contents-edit/edit-content-graphor'
import EditContentWhiteboard from 'pages/kelas/contents-edit/edit-content-whiteboard'

// Quiz
import KelolaQuiz from 'pages/quiz'
import AddQuiz from 'pages/quiz/add'
import EditQuiz from 'pages/quiz/edit'
import DaftarPertanyaanQuiz from 'pages/quiz/detail'
import PreviewQuiz from 'pages/quiz/preview'
import PreviewQuizAnswerSiswa from 'pages/quiz/preview-quiz-answer-siswa'
import AddPilihanGandaQuiz from 'pages/quiz/pilihan-ganda/add'
import AddGraphorQuiz from 'pages/quiz/graphor/add-question-graphor'
import AddUraianQuiz from 'pages/quiz/uraian/add'
import EditPilihanGandaQuiz from 'pages/quiz/pilihan-ganda/edit'
import EditUraianQuiz from 'pages/quiz/uraian/edit'
import EditGraphorQuiz from 'pages/quiz/graphor/edit-question-graphor'

// Survey Engine
import SurveyEngine from 'pages/survey-engine'
import AddSurvey from 'pages/survey-engine/add'
import EditSurvey from 'pages/survey-engine/edit'
import OverviewSurvey from 'pages/survey-engine/overview-survey'
import AddPilihanGandaSurvey from 'pages/survey-engine/pilihan-ganda/add'
import AddUraianSurvey from 'pages/survey-engine/uraian/add'
import EditPilihanGandaSurvey from 'pages/survey-engine/pilihan-ganda/edit'
import EditUraianSurvey from 'pages/survey-engine/uraian/edit'
import ResultSurvey from 'pages/survey-engine/survey-result'

// Assignment
import KelolaAssignment from 'pages/assignment'
import AddAssignment from 'pages/assignment/add'
import EditAssignment from 'pages/assignment/edit'
import ListAssignmentSiswa from 'pages/assignment/list-assignment-siswa'
import DetailAssignmentSiswa from 'pages/assignment/list-assignment-siswa/detail-assignment-siswa'
import UploadAssigment from 'pages/upload-assignment'

// Forum
import Forum from 'pages/forum'
import AddForum from 'pages/forum/add'
import EditForum from 'pages/forum/edit'
import DetailForum from 'pages/forum/detail'

// Report
import AktivitasKelas from 'pages/aktivitas-kelas'
import ReportAktivitasKelas from 'pages/aktivitas-kelas/report'
import AktivitasSiswa from 'pages/aktivitas-siswa'
import ReportTimeActivity from 'pages/aktivitas-siswa/timeactivity'

// Activity Class Progress
import IndexProgressActivityClass from 'pages/aktivitas-siswa/progress'
import IndexProfileActivitySiswa from 'pages/aktivitas-siswa/profile'
import InfografikAktivitasSiswa from 'pages/aktivitas-siswa/infografik'
import DetailKelasActivitySiswa from 'pages/aktivitas-siswa/progress/detail-kelas'

// Video conference
import VideoConference from 'pages/video-conference'
import TambahConference from 'pages/video-conference/add'
import EditConference from 'pages/video-conference/edit'

// Video konfrensi siswa
import VideoConferenceSiswa from 'pages/video-conference-siswa'
import VideoConferenceOrtu from 'pages/video-conference-ortu'

// Files Siswa
import FileSiswa from 'pages/files-siswa'

// Badges Collection Siswa
import BadgesCollection from 'pages/badges-collection'

// Group Siswa
import GroupSiswa from 'pages/group-siswa'
import GroupListSiswa from 'pages/group-siswa/list-siswa'
import GroupListKelas from 'pages/group-siswa/list-kelas'

// Forum Siswa
// import ForumSiswa from 'pages/forum-siswa'
// import ForumSiswaDetail from 'pages/forum-siswa/forumDetail'
// import TambahForumSiswa from 'pages/forum-siswa/add'
// import EditForumSiswa from 'pages/forum-siswa/edit'

// Katalog kelas siswa
import KatalogKelasSiswa from 'pages/katalog-kelas-siswa'
import KelasOverviewSiswa from 'pages/katalog-kelas-siswa/overview'
import ContentPlayerSiswa from 'pages/katalog-kelas-siswa/content-player'

// Chat Siswa
// import ChatSiswa from 'pages/chat-siswa'

// Sertifikasi siswa
import SertifikasiSiswa from 'pages/sertifikasi-siswa'

// Progress Siswa
// import ProgressSiswa from 'pages/progress-siswa'
// import ProgressSiswaDetailKelas from 'pages/progress-siswa/progress-kelas/detail-kelas'

// ZoomPlayer
import ZoomPlayer from 'pages/zoom-player'

import GroupTeachers from 'pages/group-teachers'

import WordCloud from 'pages/wordcloud'
import DragNdrop from 'pages/wordcloud/DragNDrop'

// Graphic Organizer
import GrapicOrganizer from 'pages/graphic-organizer'
import AddSort from 'pages/graphic-organizer/sort/add'
import EditSort from 'pages/graphic-organizer/sort/edit'
import AddGroup from 'pages/graphic-organizer/group/add'
import EditGroup from 'pages/graphic-organizer/group/edit'
import EvaluasiGraphor from 'pages/graphic-organizer/evaluasi-siswa-list'

// Kelola Feedback
import Feedback from 'pages/feedback'
import AddFeedback from 'pages/feedback/add'
import EditFeedback from 'pages/feedback/edit'
import ReportFeedback from 'pages/feedback/report'

// Dashboard Content
import ContentDashboard from 'pages/kelas/content-dashboard/content-dashboard'

// Siswa List OrangTua
import SiswaListOrangtua from 'pages/siswa-list-orangtua/siswa-list-orangtua'
// import SiswaDetailOrangtua from 'pages/siswa-list-orangtua/progress-siswa';
// import SiswaDetailMapel from 'pages/siswa-list-orangtua/progress-siswa/progress-kelas/detail-kelas'

// Component
import ProgresSiswaComponent from './component/progress-siswa'
import ProgressSiswaDetailMatpel from './component/progress-siswa/progress-kelas/detail-kelas'

import KelolaGrade from 'pages/kelola-grade'
import KelolaGradeAdd from 'pages/kelola-grade/add'
import KelolaGradeEdit from 'pages/kelola-grade/edit'

// Notification
import Notification from 'pages/notification'

// Tahun Ajaran aka Academic Year
import AcademicYear from 'pages/academic-year'
import Semesters from 'pages/academic-year/semesters'
import Reportperiod from 'pages/academic-year/report-period'

// Urutan Mapel Rapor
import OrderSubjectReport from 'pages/order-subject-report'

// Input Rapor
import InputRapor from 'pages/input-rapor'

// Input Kindness
import InputKindness from 'pages/input-kindness'

// Input Rapor period

import InputRaporPeriod from 'pages/input-rapor-period'

// Nilai Siswa
import Scores from 'pages/scores/scores'
import ScoresDetail from 'pages/scores/scores-detail'
import ScoresSiswa from 'pages/scores-siswa'

// SetAllScores
import SetAllScores from 'pages/input-rapor-all'
import SetAllScoresSet from 'pages/input-rapor-all/SetScore'
import PreviewScore from 'pages/AllScores/SetScore'

// Input Rapor Block
import RaporBlockAll from 'pages/input-rapor-block-all'
import SetScoreRaporBlockAll from 'pages/input-rapor-block-all/SetScore'
import PreviewRaporBlock from 'pages/input-rapor-block-all/preview'

// Rapor Approval
import RaporApproval from 'pages/rapor-approval'

// Rapor Period Approval
import RaporPeriodApproval from 'pages/rapor-period-approval'

// Download Rapor Teacher
import RaporTeacherDownload from 'pages/rapor-teacher-download'

// My Rapor
import MyRapor from 'pages/my-rapor'

// Firebase
import './util/firebase'

// Share Kelas
import ShareKelas from 'pages/share-kelas'
import KelolaShareKelas from 'pages/share-kelas/kelola-share'
import ApprovalShareClass from 'pages/share-kelas/approval-share'

// Rapor Kindness
import Kindness from 'pages/kindness-program'
import AddKindness from 'pages/kindness-program/add'
import EditKindness from 'pages/kindness-program/edit'
import OverviewKindness from 'pages/kindness-program/overview'

// Config
import Config from 'pages/config'
import AddGrade from 'pages/kelas/add-grade'
import GradeListContent from 'pages/grade/list-content'
import EditGrade from 'pages/kelas/edit-grade'
import GradeListSelectedContentCreate from 'pages/grade/list-content/content/create'


export default function Routes() {
  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const consumeQuery = () => new URLSearchParams(location.search)
  const query = consumeQuery()


  /* ====================================== Consume Context ===================================== */
  const { putContentActivities, putClassActivities, getRequest, postRequest } = useBaseContext()

  /* ========================================= Functions ======================================== */
  async function updateActivities() {
    await putContentActivities()
    await putClassActivities()
  }

  async function windowOnBlur() {
    window.$(window).blur(async function (e) {
      let login_id = localStorage.getItem('login_id')
      if (login_id) {
        await getRequest('onleave-page')
      }
    })
  }

  async function classAndContentActivitiesCheck() {
    if (localStorage.getItem('prev_url') === null) {
      localStorage.setItem('prev_url', '-')
    } else {
      if (localStorage.getItem('prev_url').includes('content-player')) {
        updateActivities()
      } else {
        localStorage.setItem('class_id', '-')
        localStorage.setItem('class_completed', '-')
      }
      localStorage.setItem('prev_url', location.pathname)
    }
    clearInterval(window.intervalID)
    clearInterval(window.quizTimer)
    window.clockQuiz = null
  }

  async function checkTriggerUpdate() {
    let login_id = localStorage.getItem('login_id')
    if (login_id) {
      let form = new FormData()
      form.append('flag', 'relogin')
      let res = await postRequest('triggerUpdateUsers', form)
      if (res) {
        console.log('TRIGGER UPDATE DATA', res)
      }
    }
  }

  useEffect(() => {
    let old_console_log = console.log
    console.log = function () {
      if (debug) {
        old_console_log.apply(this, arguments)
      }
    }
    console.log('TEST LOG')
    // slow query affect
    checkTriggerUpdate()
    classAndContentActivitiesCheck()
  }, [location])

  useEffect(() => {
    windowOnBlur()
  }, [])

  const userAuthenticated = JSON.parse(localStorage.getItem('user')) || query.get('token')

  if (userAuthenticated !== null) {
    return (
      <Switch>
        <Route
          exact
          path="/"
        >
          <LandingPage />
          {/* <Login /> */}
        </Route>
        <Route
          exact
          path="/datatable"
        >
          <DataTable />
        </Route>
        <Route path="/template">
          <Admin />
        </Route>

        {/* Login & Signup */}
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/reset">
          <Reset />
        </Route>
        <Route path="/forgot-password/:token">
          <NewPassword />
        </Route>

        {/* Dashboard */}
        <Route path="/dashboard">
          <Dashboard />
        </Route>

        {/* Profile */}
        <Route path="/profile">
          <Profile />
        </Route>

        {/* Manajemen User */}
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/users-add-profile">
          <AddProfile />
        </Route>
        <Route path="/users-add">
          <AddUsers />
        </Route>
        <Route path="/users-edit">
          <EditUsers />
        </Route>
        <Route path="/roles">
          <Roles />
        </Route>
        <Route path="/roles-add">
          <RolesAdd />
        </Route>
        <Route path="/roles-edit">
          <RolesEdit />
        </Route>
        <Route path="/roles-hak-akses">
          <RoleHakAkses />
        </Route>
        <Route
          exact
          path="/manage-siswa/:userId"
        >
          <ManageSiswa />
        </Route>

        {/* Kategori Kelas */}
        <Route path="/kelas-kategori">
          <KelasKategori />
        </Route>
        <Route path="/kelas-kategori-add">
          <KelasKategoriAdd />
        </Route>
        <Route path="/kelas-kategori-edit">
          <KelasKategoriEdit />
        </Route>

        {/* Kelas */}
        <Route
          exact
          path="/kelas"
        >
          <Kelas />
        </Route>
        <Route
          exact
          path="/share-kelas-approval"
        >
          <ApprovalShareClass />
        </Route>
        <Route
          exact
          path="/share-kelas"
        >
          <ShareKelas />
        </Route>
        <Route
          exact
          path="/kelola-share-kelas"
        >
          <KelolaShareKelas />
        </Route>
        <Route
          exact
          path="/kelas/:kelasId"
        >
          <KelasOverview />
        </Route>
        <Route
          exact
          path="/kelas/:kelasId/content/:contentId"
        >
          <ContentDashboard />
        </Route>

        {/* Group Instruktur */}
        <Route
          exact
          path={'/group-instruktur'}
        >
          <GroupTeachers />
        </Route>
        {/* <Route exact path="/kelas-overview">
                    <KelasOverview />
                </Route> */}
        <Route path="/kelas-add">
          <KelasAdd />
        </Route>
        <Route path="/kelas-edit">
          <KelasEdit />
        </Route>

        {/* Kelas Grade */}
        <Route path="/kelas/:kelasId/grade/add">
          <AddGrade />
        </Route>

        <Route path="/kelas/:kelasId/grade/:gradeId/edit">
          <EditGrade />
        </Route>

        <Route path="/kelas/:kelasId/grade/:gradeId/list-content">
          <GradeListContent />
        </Route>

        <Route path="/kelas/:kelasId/grade/:gradeId/content/:contentId/create">
          <GradeListSelectedContentCreate />
        </Route>

        <Route path="/kelas/:kelasId/grade/:gradeId/content/:contentId/edit">
          <GradeListSelectedContentCreate isOnEdit />
        </Route>

        {/* Kelas Contents Add*/}
        <Route path="/kelas/:kelasId/add-section">
          <AddSectionKelas />
        </Route>
        <Route path="/kelas/:kelasId/add-content-text">
          <AddContentText />
        </Route>
        <Route path="/kelas/:kelasId/add-content-audio">
          <AddContentAudio />
        </Route>
        <Route path="/kelas/:kelasId/add-content-video">
          <AddContentVideo />
        </Route>
        <Route path="/kelas/:kelasId/add-content-document">
          <AddContentDocument />
        </Route>
        <Route path="/kelas/:kelasId/add-content-iframe">
          <AddContentIframe />
        </Route>
        <Route path="/kelas/:kelasId/add-content-quiz">
          <AddContentQuiz />
        </Route>
        <Route path="/kelas/:kelasId/add-content-survey">
          <AddContentSurvey />
        </Route>
        <Route path="/kelas/:kelasId/add-content-assignment">
          <AddContentAssignment />
        </Route>
        <Route path="/kelas/:kelasId/add-content-graphor">
          <AddContentGraphor />
        </Route>
        <Route path="/kelas/:kelasId/add-content-whiteboard">
          <AddContentWhiteboard />
        </Route>

        {/* Kelas Contents Edit */}
        <Route path="/kelas/:kelasId/edit-section/:sectionId">
          <EditSectionKelas />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-text/:contentId">
          <EditContentText />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-audio/:contentId">
          <EditContentAudio />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-video/:contentId">
          <EditContentVideo />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-document/:contentId">
          <EditContentDocument />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-iframe/:contentId">
          <EditContentIframe />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-quiz/:contentId">
          <EditContentQuiz />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-survey/:contentId">
          <EditContentSurvey />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-assignment/:contentId">
          <EditContentAssignment />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-graphor/:contentId">
          <EditContentGraphor />
        </Route>
        <Route path="/kelas/:kelasId/edit-content-whiteboard/:contentId">
          <EditContentWhiteboard />
        </Route>

        {/* Group Siswa */}
        <Route path="/group">
          <Group />
        </Route>
        <Route path="/group-add">
          <GroupAdd />
        </Route>
        <Route path="/group-detail/:idGroup">
          <GroupDetail />
        </Route>

        <Route path="/menus">
          <Menu />
        </Route>
        <Route path="/menus-table">
          <MenuTable />
        </Route>
        <Route path="/menus-add">
          <MenuAdd />
        </Route>
        <Route path="/menus-edit/:id">
          <MenuAdd />
        </Route>

        {/* Profile Setting */}
        <Route path="/gamification">
          <Gamification />
        </Route>
        <Route path="/costumize-badges">
          <CostumizeBadges />
        </Route>
        <Route path="/basic-profile">
          <BasicProfile />
        </Route>
        <Route path="/users-setting">
          <ProfileSettingUsers />
        </Route>

        <Route path="/chats">
          <Chat />
        </Route>
        <Route path="/kalender">
          <Kalender />
        </Route>

        {/* <Route path="/konten-detail-teks">
                    <KontenTeks />
                </Route>
                <Route path="/konten-detail-audio">
                    <KontenAudio />
                </Route> */}

        {/* Survey Engine */}
        <Route
          exact
          path="/survey-engine"
        >
          <SurveyEngine />
        </Route>
        <Route path="/survey-engine/add">
          <AddSurvey />
        </Route>
        <Route path="/survey-engine/edit/:surveyId">
          <EditSurvey />
        </Route>
        <Route
          exact
          path="/survey-engine/:surveyId"
        >
          <OverviewSurvey />
        </Route>
        <Route path="/survey-engine/survey-result/:surveyId">
          <ResultSurvey />
        </Route>
        <Route path="/survey-engine/:surveyId/add-pilihan-ganda">
          <AddPilihanGandaSurvey />
        </Route>
        <Route path="/survey-engine/:surveyId/add-uraian">
          <AddUraianSurvey />
        </Route>
        <Route path="/survey-engine/edit-pilihan-ganda/:questionId">
          <EditPilihanGandaSurvey />
        </Route>
        <Route path="/survey-engine/edit-uraian/:questionId">
          <EditUraianSurvey />
        </Route>

        {/* Kelola Assignment */}
        <Route
          exact
          path="/kelola-assignment"
        >
          <KelolaAssignment />
        </Route>
        <Route path="/kelola-assignment/add">
          <AddAssignment />
        </Route>
        <Route path="/kelola-assignment/edit/:assignmentId">
          <EditAssignment />
        </Route>
        <Route
          exact
          path="/kelola-assignment/:assignmentId"
        >
          <ListAssignmentSiswa />
        </Route>
        <Route
          exact
          path="/kelola-assignment/:assignmentId/detail/:userId"
        >
          <DetailAssignmentSiswa />
        </Route>
        <Route
          exact
          path="/upload-assignment"
        >
          <UploadAssigment />
        </Route>

        {/* Forum */}
        <Route
          exact
          path="/forum"
        >
          <Forum />
        </Route>
        <Route path="/forum-add">
          <AddForum />
        </Route>
        <Route
          exact
          path="/forum-edit/:forumId"
        >
          <EditForum />
        </Route>
        <Route path="/forum/:forumId">
          <DetailForum />
        </Route>

        {/* Laporan */}
        <Route path="/laporan-aktifitas-kelas">
          <AktivitasKelas />
        </Route>
        <Route path="/laporan-aktifitas-kelas-detail/:kelasId">
          <ReportAktivitasKelas />
        </Route>
        <Route path="/laporan-aktifitas-siswa">
          <AktivitasSiswa />
        </Route>
        <Route
          exact
          path="/laporan-aktifitas-siswa-progress/:siswaId"
        >
          <IndexProgressActivityClass />
        </Route>
        <Route
          exact
          path="/laporan-aktifitas-siswa-progress/:siswaId/:kelasId"
        >
          <DetailKelasActivitySiswa />
        </Route>
        <Route path="/laporan-aktifitas-siswa-profile/:siswaId">
          <IndexProfileActivitySiswa />
        </Route>
        <Route path="/laporan-aktifitas-siswa-infografik/:siswaId">
          <InfografikAktivitasSiswa />
        </Route>
        <Route path="/laporan-aktifitas-waktu-siswa">
          <ReportTimeActivity />
        </Route>

        {/* Video Conference */}
        <Route path="/video-conference-siswa">
          <VideoConferenceOrtu />
        </Route>
        <Route path="/video-conference">
          <VideoConference />
        </Route>
        <Route path="/video-conference-add">
          <TambahConference />
        </Route>
        <Route path="/video-conference-edit/:id">
          {/*<EditConference />*/}
          <TambahConference />
        </Route>
        <Route path="/siswa-video-konferensi">
          <VideoConferenceSiswa />
        </Route>
        <Route path="/siswa-files">
          <FileSiswa />
        </Route>

        {/* Group Siswa */}
        <Route
          exact
          path="/siswa-group"
        >
          <GroupSiswa />
        </Route>
        <Route path="/siswa-group/:groupId/list-siswa">
          <GroupListSiswa />
        </Route>
        <Route path="/siswa-group/:groupId/list-kelas">
          <GroupListKelas />
        </Route>

        {/* Forum Siswa */}
        {/* <Route path="/siswa-forum">
                    <Forum />
                </Route>
                <Route path="/siswa-forum-detail">
                    <ForumSiswaDetail />
                </Route>
                <Route path="/siswa-forum-add">
                    <TambahForumSiswa />
                </Route>
                <Route path="/siswa-forum-edit">
                    <EditForumSiswa />
                </Route> */}

        {/* Katalog Kelas Siswa */}
        <Route
          exact
          path="/katalog-kelas"
        >
          <KatalogKelasSiswa />
        </Route>
        <Route
          exact
          path="/katalog-kelas/:classId"
        >
          <KelasOverviewSiswa />
        </Route>
        <Route path="/katalog-kelas/:classId/content-player">
          <ContentPlayerSiswa />
        </Route>

        {/* Chat Siswa */}
        {/* <Route path="/chat-siswa">
                    <ChatSiswa />
                </Route> */}

        <Route path="/siswa-kalender">
          <Kalender />
        </Route>
        <Route path="/siswa-sertifikasi">
          <SertifikasiSiswa />
        </Route>

        {/* Progress Siswa */}
        <Route
          exact
          path="/progress-siswa"
        >
          {/* <ProgressSiswa /> */}
          <ProgresSiswaComponent />
        </Route>
        <Route path="/progress-siswa/kelas/:kelasId">
          {/* <ProgressSiswaDetailKelas /> */}
          <ProgressSiswaDetailMatpel />
        </Route>

        <Route path="/zoom-player">
          <ZoomPlayer />
        </Route>

        {/* Kelola Quiz */}
        <Route
          exact
          path="/kelola-quiz"
        >
          <KelolaQuiz />
        </Route>
        <Route path="/kelola-quiz/add">
          <AddQuiz />
        </Route>
        <Route path="/kelola-quiz/edit/:quizId">
          <EditQuiz />
        </Route>
        <Route
          exact
          path="/kelola-quiz/:quizId"
        >
          <DaftarPertanyaanQuiz />
        </Route>
        <Route
          exact
          path="/kelola-quiz/preview-quiz/:quizId"
        >
          <PreviewQuiz />
        </Route>
        <Route
          exact
          path="/kelola-quiz/preview-quiz/:quizId/answer-siswa/:siswaId"
        >
          <PreviewQuizAnswerSiswa />
        </Route>
        <Route path="/kelola-quiz/:quizId/add-pilihan-ganda">
          <AddPilihanGandaQuiz />
        </Route>
        <Route path="/kelola-quiz/:quizId/add-uraian">
          <AddUraianQuiz />
        </Route>
        <Route path="/kelola-quiz/:quizId/add-graphor">
          <AddGraphorQuiz />
        </Route>
        <Route path="/kelola-quiz/edit-pilihan-ganda/:questionId">
          <EditPilihanGandaQuiz />
        </Route>
        <Route path="/kelola-quiz/edit-uraian/:questionId">
          <EditUraianQuiz />
        </Route>
        <Route path="/kelola-quiz/edit-graphor/:questionId">
          <EditGraphorQuiz />
        </Route>

        <Route path="/profiles">
          <ProfileSuperAdmin />
        </Route>
        <Route path="/add-profiles">
          <AddProfileSuperAdmin />
        </Route>
        <Router path={'/word-cloud'}>
          <WordCloud />
        </Router>
        <Router path={'/drag-drop'}>
          <DragNdrop />
        </Router>
        <Route path={'/badge-collections'}>
          <BadgesCollection />
        </Route>

        {/* GRAPIC ORGANIZER */}
        <Route
          exact
          path={'/graphic-organizer'}
        >
          <GrapicOrganizer />
        </Route>
        <Route path={'/graph-sort-add'}>
          <AddSort />
        </Route>
        <Route path={'/graph-sort-edit/:id'}>
          <EditSort />
        </Route>
        <Route path={'/graph-group-add'}>
          <AddGroup />
        </Route>
        <Route path={'/graph-group-edit/:id'}>
          <EditGroup />
        </Route>
        <Route path={'/graphic-organizer/eval/:graphorId'}>
          <EvaluasiGraphor />
        </Route>

        {/* KELOLA FEEDBACK */}
        <Route path={'/kelola-feedback'}>
          <Feedback />
        </Route>
        <Route path={'/kelola-feedback-add'}>
          <AddFeedback />
        </Route>
        <Route path={'/kelola-feedback-edit/:idFeed'}>
          <EditFeedback />
        </Route>
        <Route path={'/kelola-feedback-report/:idFeed'}>
          <ReportFeedback />
        </Route>

        {/* SISWA LIST ORANG TUA */}
        <Route
          exact
          path="/siswa"
        >
          <SiswaListOrangtua />
        </Route>
        <Route
          exact
          path="/siswa/:siswaId"
        >
          {/* <SiswaDetailOrangtua /> */}
          <ProgresSiswaComponent />
        </Route>
        <Route
          exact
          path="/siswa/:siswaId/mapel/:kelasId"
        >
          {/* <SiswaDetailMapel /> */}
          <ProgressSiswaDetailMatpel />
        </Route>

        <Route path={'/auth-token'}>
          <SetToken />
        </Route>

        {/* Kelola Grade */}
        <Route path={'/kelola-grade'}>
          <KelolaGrade />
        </Route>
        <Route path={'/kelola-grade-add'}>
          <KelolaGradeAdd />
        </Route>
        <Route path={'/kelola-grade-edit/:id'}>
          <KelolaGradeEdit />
        </Route>

        {/* Notification page */}
        <Route path={'/all-notification'}>
          <Notification />
        </Route>

        {/* Tahun Ajaran aka Academic Year */}
        <Route
          exact
          path="/academic-year"
        >
          <AcademicYear />
        </Route>

        {/* Urutan Mapel Rapor */}
        <Route
          exact
          path="/order-subject-report"
        >
          <OrderSubjectReport />
        </Route>

        <Route path="/academic-year/:ta_tahun_code">
          <Semesters />
        </Route>

        {/* Input Kindness */}
        <Route
          exact
          path="/input-rapor-p5"
        >
          <InputKindness />
        </Route>
        <Route path="/report-period/:ta_tahun_code/:startdate/:endDate">
          <Reportperiod />
        </Route>

        {/* Input Rapor */}
        <Route
          exact
          path="/input-rapor"
        >
          <InputRapor />
        </Route>

        {/* Input Rapor Period */}
        <Route
          exact
          path="/input-rapor-period"
        >
          <InputRaporPeriod />
        </Route>

        {/* Nilai Siswa */}
        <Route
          exact
          path="/scores"
        >
          <Scores />
        </Route>
        <Route path="/scores/:userId">
          <ScoresDetail />
        </Route>
        <Route
          exact
          path="/scores-siswa"
        >
          <ScoresSiswa />
        </Route>

        {/* isi rapot */}
        <Route
          exact
          path="/input-rapor-all"
        >
          <SetAllScores />
        </Route>
        <Route
          exact
          path="/input-rapor-all/:userId/:semester"
        >
          <SetAllScoresSet />
        </Route>
        <Route
          exact
          path="/preview-rapor/:userId/:semester"
        >
          <PreviewScore />
        </Route>

        {/* isi Rapor Block */}
        <Route
          exact
          path="/input-rapor-block-all"
        >
          <RaporBlockAll />
        </Route>
        <Route
          exact
          path="/input-rapor-block-all/:userId"
        >
          <SetScoreRaporBlockAll />
        </Route>
        <Route
          exact
          path="/preview-rapor-block/:userId"
        >
          <PreviewRaporBlock />
        </Route>
        <Route
          exact
          path="/mobile/preview-rapor-block/:userId"
        >
          <PreviewRaporBlock mobile />
        </Route>

        {/* Rapor Approval */}
        <Route
          exact
          path="/rapor-approval"
        >
          <RaporApproval />
        </Route>

        {/* Rapor Period Approval */}
        <Route
          exact
          path="/rapor-period-approval"
        >
          <RaporPeriodApproval />
        </Route>

        {/* Download Rapor Teacher */}
        <Route
          exact
          path="/rapor-list"
        >
          <RaporTeacherDownload />
        </Route>

        {/* My Rapor */}
        <Route
          exact
          path="/my-rapor"
        >
          <MyRapor />
        </Route>
        
        {/* Kindness Program */}
        <Route
          exact
          path={'/kindness-program'}
        >
          <Kindness />
        </Route>
        <Route
          exact
          path={'/kindness-program/add'}
        >
          <AddKindness />
        </Route>
        <Route
          exact
          path={'/kindness-program/edit/:id'}
        >
          <EditKindness />
        </Route>
        <Route
          exact
          path={'/kindness-program/overview/:id'}
        >
          <OverviewKindness />
        </Route>
        <Route path="/config">
          <Config />
        </Route>
        <Route component={NotFound} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/reset">
          <Reset />
        </Route>
        <Route path="/forgot-password/:token">
          <NewPassword />
        </Route>
        <Route component={DefaultNoAuth} />
      </Switch>
    )
  }
}
