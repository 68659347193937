import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import InvalidText from 'component/invalid/text'
import TypeScoreForm from 'component/type-score-form'

const FormAddQuiz = () => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  // FORM LIST
  const [listTypeScore, setListTypeScore] = useState([])

    // FORM INPUT STATE
  const [title, setTitle]                         = useState('')
  const [time, setTime]                           = useState('')
  const [quizCode, setQuizCode]                   = useState('')
  const [status, setStatus]                       = useState(0)
  const [isTypeScoreActive, setIsTypeScoreActive] = useState(false)
  const [typeScore, setTypeScore]                 = useState('')

    // FORM VALIDATION STATE
  const [isTitleInvalid, setIsTitleInvalid]       = useState(false)
  const [isTimeInvalid, setIsTimeInvalid]         = useState(false)
  const [isQuizCodeInvalid, setIsQuizCodeInvalid] = useState(false)
  const [isQuizCodeTooShort, setQuizCodeTooShort] = useState(false)
  const [isQuizCodeSpecial, setIsQuizCodeSpecial] = useState(false)

  /* =========================================== Refs =========================================== */
  const refTitle    = useRef()
  const refQuizCode = useRef()
  const refTime     = useRef()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/kelola-quiz', name: 'Kelola Quiz' }]

  /* ========================================= Functions ======================================== */
  async function getTypeScore() {
    const response = await getRequest('type-score')
    if (response) {
      setListTypeScore(response.data)
    }
  }

  function handleChangeName(event) {
    if (event.target.value) {
      setIsTitleInvalid(false)
    }
    setTitle(event.target.value)
  }

  function handleChangeTime(event) {
    if (event.target.value) {
      const value = Math.floor(Math.abs(event.target.value))
      setTime(value)
      setIsTimeInvalid(false)
    } else {
      setTime('')
    }
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleChangeQuizCode(event) {
    if (event.target.value.length === 5) {
      setQuizCodeTooShort(false)
      setIsQuizCodeInvalid(false)
    }
    if (checkSpecialChars(event.target.value) === false) {
      setIsQuizCodeSpecial(false)
    }
    setQuizCode(event.target.value.toUpperCase())
  }

  function handleActiveTypeScore() {
    if (isTypeScoreActive) {
      setIsTypeScoreActive(false)
      setTypeScore('')
    } else {
      setIsTypeScoreActive(true)
    }
  }

  function handleChangeTypeScore(event) {
    setTypeScore(event.target.value)
  }

  function checkSpecialChars(string) {
    //eslint-disable-next-line
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return specialChars.test(string) //return true when contains special chars
  }

  function handleFormValidation() {
    if (
      title &&
      time &&
      quizCode.length === 5 &&
      checkSpecialChars(quizCode) === false
    ) {
      setIsTitleInvalid(false)
      setIsTimeInvalid(false)
      setIsQuizCodeInvalid(false)
      setQuizCodeTooShort(false)
      setIsQuizCodeSpecial(false)
      handleFormSubmit(title, time * 60, quizCode, status, typeScore)
    } else {
      if (!time) {
        setIsTimeInvalid(true)
        refTime.current.focus()
      } else {
        setIsTimeInvalid(false)
      }

      if (!quizCode) {
        setIsQuizCodeInvalid(true)
        refQuizCode.current.focus()
      } else {
        setIsQuizCodeInvalid(false)
      }

      if (quizCode.length !== 5) {
        setQuizCodeTooShort(true)
        refQuizCode.current.focus()
      } else {
        setQuizCodeTooShort(false)
      }

      if (checkSpecialChars(quizCode)) {
        setIsQuizCodeSpecial(true)
        refQuizCode.current.focus()
      } else {
        setIsQuizCodeSpecial(false)
      }

      if (!title) {
        setIsTitleInvalid(true)
        refTitle.current.focus()
      } else {
        setIsTitleInvalid(false)
      }

      window.notification('Mohon', 'lengkapi data')
    }
  }

  async function handleFormSubmit(title, time, quizcode, active, typeScore) {
    let form = new FormData()
    form.append('title', title)
    form.append('time', time)
    form.append('is_template', 1)
    form.append('quizcode', quizcode)
    form.append('active', active)
    if (typeScore) {
      form.append('type_score', parseInt(typeScore))
    }

    const response = await postRequest('quiz', form)
    if (response) {
      if (response.message == 'Berhasil input data') {
        window.notification('Quiz', 'berhasil ditambahkan', 'success')
        history.push(`/kelola-quiz/${response.data.id}`, { existingData: response.data })
        // history.goBack()
      } else if (response.message == 'Kode quiz sudah digunakan') {
        window.notification('Kode quiz', 'sudah digunakan', 'warning')
        refQuizCode.current.focus()
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getTypeScore()

    return () => {
      setListTypeScore([])
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Kelola Quiz"
          linkName="Tambah Quiz"
          links={links}
        />
        
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5 className="modal-title">
                  <i className="fas fa-pencil-ruler mr-1" />
                  Tambah Quiz
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Title Quiz */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="title-quiz"
                        >
                          <span className="mr-1">Nama quiz</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={title}
                            onChange={(event) => handleChangeName(event)}
                            ref={refTitle}
                            type="text"
                            className={`${
                              isTitleInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="title-quiz"
                            placeholder="Judul quiz"
                          />
                          {isTitleInvalid ? (
                            <InvalidText name={'Nama'} />
                          ) : null}
                        </div>
                      </div>

                      {/* Input Quiz Code */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="kode-quiz"
                        >
                          <span className="mr-1">Kode quiz</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={quizCode}
                            maxLength={5}
                            onChange={(event) => handleChangeQuizCode(event)}
                            ref={refQuizCode}
                            type="text"
                            className={`${
                              isQuizCodeInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="kode-quiz"
                            placeholder="5 karakter kombinasi huruf/angka"
                          />
                          {isQuizCodeInvalid ? (
                            <InvalidText name={'Kode kuis'} />
                          ) : null}
                          {isQuizCodeTooShort ? (
                            <span className="col small form-text text-danger">
                              Kode quiz minimal 5 karakter.
                            </span>
                          ) : null}
                          {isQuizCodeSpecial ? (
                            <span className="col small form-text text-danger">
                              Kode quiz hanya berisi kombinasi huruf/angka.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Input Time Quiz */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="time-quiz"
                        >
                          <span className="mr-1">Waktu pengerjaan</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-3">
                          <input
                            value={time}
                            min={0}
                            onChange={(event) => handleChangeTime(event)}
                            ref={refTime}
                            type="number"
                            className={`${
                              isTimeInvalid ? 'is-invalid' : ''
                            } form-control`}
                            placeholder="Menit"
                          />
                          {isTimeInvalid ? <InvalidText name="Waktu" /> : null}
                        </div>
                        <label className="col-form-label col-sm-6 text-muted">
                          <span className="mr-1">Dalam menit</span>
                        </label>
                      </div>

                      {/* Select type score */}
                      <TypeScoreForm
                        listTypeScore={listTypeScore}
                        typeScore={typeScore}
                        isTypeScoreActive={isTypeScoreActive}
                        handleActiveTypeScore={handleActiveTypeScore}
                        handleChangeTypeScore={handleChangeTypeScore}
                      />

                      {/* Select Quiz Status */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="status-quiz"
                        >
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-status-quiz"
                            />
                            <label
                              htmlFor="switch-status-quiz"
                              className="cr"
                            ></label>
                          </div>
                          <label htmlFor="switch-status-quiz">
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>

                      {/* Form keterangan */}
                      <div className="form-group d-flex">
                        <label className="text-muted mr-2">Keterangan:</label>
                        <span className="text-danger mr-1">*</span>
                        <label className="text-muted">Wajib diisi</label>
                      </div>

                      {/* Form button */}
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-outline-danger mr-2"
                          onClick={() => history.goBack()}
                        >
                          Kembali
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => handleFormValidation()}
                        >
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default FormAddQuiz
