import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Select from 'react-select'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import Modal from 'component/modal'
import { avatarDefaultUser } from 'util/constant'

export default function AktifitasBelajar() {
  /* ========================================== Helpers ========================================= */
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [windowWidth, setWidth] = useState(window.innerWidth)

  /* Data Reports */
  const [activity, setActivity]                             = useState([])
  const [countFeedbackSend, setCountFeedbackSend]           = useState(0)
  const [countFeedbackRemaining, setCountFeedbackRemaining] = useState(0)
  const [dataFeedbackRemaining, setDataFeedbackRemaining]   = useState([])
  const [duration, setDuration]                             = useState(null)
  const [dataUser, setDataUser]                             = useState(null)
  const [feedbackAssignment, setFeedbackAssignment]         = useState(null)
  const [grafikGrupKecil, setGrafikGrupKecil]               = useState(null)

  /* Filters */
  const [classes, setClasses]             = useState([])
  const [startDate, setStartDate]         = useState('')
  const [endDate, setEndDate]             = useState('')
  const [selectClass, setSelectClass]     = useState(null)
  const [loadingFilter, setLoadingFilter] = useState(false)

  /* =========================================== Refs =========================================== */
  let isMounted = useRef(true)
  
  /* ========================================= Functions ======================================== */
  async function getSubMataPelajaran() {
    const response = await getRequest(
      `report-aktifitas-siswa/sub-mata-pelajaran?user_id=${params?.siswaId}`,
    )
    if (response) {
      if (isMounted.current === true) {
        const options = response.data.map((item) => ({
          value: item.id,
          label: item.sub_mata_pelajaran,
        }))
        setClasses(options)
      }
    }
  }

  async function getActivity() {
    let query = ''
    if (selectClass) query = query + `&class_id=${selectClass.value}`
    if (startDate) query = query + `&start_date=${startDate}`
    if (endDate) query = query + `&end_date=${endDate}`
    setLoadingFilter(true)
    const response = await getRequest(
      `report-aktifitas-siswa/aktifitas-belajar?user_id=${params?.siswaId}${query}`,
    )
    setLoadingFilter(false)
    if (response) {
      if (isMounted.current === true) {
        setActivity(response.activity)
        setDuration(response.totalDuration)
        setCountFeedbackSend(response.feedbackContent.countSend)
        setCountFeedbackRemaining(response.feedbackContent.countRemaining)
        setDataFeedbackRemaining(response.feedbackContent.dataRemaining)
        setFeedbackAssignment(response.feedbackAssignment)
        setGrafikGrupKecil(response.groupKecil)
        if (!dataUser) setDataUser(response.dataUser)
      }
    }
  }

  const handleChangeClass = (option) => {
    setSelectClass(option)
  }

  function secondsToHms(seconds) {
    seconds = Number(seconds)
    const hour = Math.floor(seconds / 3600)
    const minutes = Math.floor(seconds / 60)
    const output =
      hour > 0
        ? `${hour} jam`
        : minutes > 0
        ? `${minutes} menit`
        : `${seconds} detik`
    return output
  }

  function updateDimensions() {
    setWidth(window.innerWidth)
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSubMataPelajaran()
    window.addEventListener('resize', updateDimensions)
    return () => {
      isMounted.current = false
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    getActivity()
  }, [selectClass, startDate, endDate])

  /* ========================================== Output ========================================== */
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Sub Mata Pelajaran</span>
                <Select
                  className="mt-2"
                  placeholder="Pilih sub mata pelajaran..."
                  isMulti={false}
                  isClearable={true}
                  options={classes}
                  onChange={handleChangeClass}
                  value={selectClass}
                  isDisabled={loadingFilter}
                  isLoading={loadingFilter}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Mulai</span>
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  disabled={loadingFilter}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Selesai</span>
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  disabled={loadingFilter}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            {selectClass || startDate || endDate ? (
              <div className="row mt-2">
                <div className="col-12">
                  <p className="m-0">Filter: </p>
                  <div className="bootstrap-tagsinput bg-light">
                    {selectClass && (
                      <span className="tag label label-info">
                        {selectClass.label}
                        <span
                          data-role="remove"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleChangeClass(null)}
                        />
                      </span>
                    )}
                    {startDate && (
                      <span className="tag label label-info">
                        {'Mulai: '}
                        {startDate}
                        <span
                          data-role="remove"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setStartDate('')}
                        />
                      </span>
                    )}
                    {endDate && (
                      <span className="tag label label-info">
                        {'Selesai: '}
                        {endDate}
                        <span
                          data-role="remove"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setEndDate('')}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row mb-2 mb-md-3">
              <div
                className="col-12"
                style={{
                  display: 'flex',
                  flexDirection: windowWidth >= 768 ? 'row' : 'column',
                  alignItems: windowWidth >= 768 ? 'start' : 'center',
                }}
              >
                <img
                  style={{
                    width: windowWidth >= 768 ? '75px' : '220px',
                    height: windowWidth >= 768 ? '75px' : '220px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                  className="mb-3 mb-md-0"
                  src={dataUser ? dataUser.linkAvatar : ''}
                  alt={dataUser ? dataUser.name : 'User'}
                  onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
                />
                <div className="ml-md-3">
                  <h5>{dataUser ? dataUser.name : ''}</h5>
                  <h6>{dataUser ? dataUser.email : ''}</h6>
                  <p>{dataUser ? dataUser.description : ''}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'column',
                    },
                    title: {
                      text: 'Aktivitas Belajar Siswa',
                    },
                    subtitle: {
                      text: '',
                    },
                    xAxis: {
                      categories: activity.categories,
                      crosshair: true,
                      title: {
                        text: 'Waktu',
                      },
                    },
                    yAxis: {
                      min: 0,
                      title: {
                        text: 'Mengerjakan Materi',
                      },
                    },
                    tooltip: {
                      headerFormat:
                        '<span style="font-size:10px">{point.key}</span><table>',
                      pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                      footerFormat: '</table>',
                      shared: true,
                      useHTML: true,
                    },
                    plotOptions: {
                      column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                      },
                    },
                    series: activity.series,
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 col-xl-3">
                <div className="card widget-statstic-card">
                  <div className="card-body">
                    <div className="card-header-left mb-3">
                      <h5 className="mb-0">Feedback Tugas</h5>
                      <p className="p-t-10 m-b-0 text-primary">
                        Memberi feedback
                      </p>
                    </div>
                    <i className="fas fa-comment st-icon bg-primary"></i>
                    <div className="text-left">
                      <h3 className="d-inline-block m-0">
                        {feedbackAssignment ? feedbackAssignment.countSend : 0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card widget-statstic-card">
                  <div className="card-body">
                    <div className="card-header-left mb-3">
                      <h5 className="mb-0">Feedback Tugas</h5>
                      <p className="p-t-10 m-b-0 text-success">
                        Menerima feedback
                      </p>
                    </div>
                    <i className="feather icon-users st-icon bg-success"></i>
                    <div className="text-left">
                      <h3 className="d-inline-block m-0">
                        {feedbackAssignment
                          ? feedbackAssignment.countReceived
                          : 0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card widget-statstic-card">
                  <div className="card-body">
                    <div className="card-header-left mb-3">
                      <h5 className="mb-0">Feedback Tugas</h5>
                      <p className="p-t-10 m-b-0 text-danger">
                        Belum memberikan feedback
                      </p>
                    </div>
                    <i className="fas fa-comment-slash st-icon bg-danger"></i>
                    <div className="text-left d-flex justify-content-between">
                      <h3 className="d-inline-block m-0">
                        {feedbackAssignment
                          ? feedbackAssignment.countRemains
                          : 0}
                      </h3>
                      {feedbackAssignment ? (
                        feedbackAssignment.countRemains > 0 ? (
                          <>
                            <button
                              data-toggle="modal"
                              data-target="#aktifitasBelajarFeedbackAssignmentRemains"
                              className="btn btn-sm btn-outline-danger"
                            >
                              Detail
                            </button>
                            <Modal
                              title="Belum memberikan feedback tugas"
                              id="aktifitasBelajarFeedbackAssignmentRemains"
                              size="modal-lg"
                            >
                              <div className="user-profile-list">
                                <div className="table-responsive p-3">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Materi</th>
                                        <th>Section</th>
                                        <th>Sub Mapel</th>
                                        <th>Feedback tersisa</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {feedbackAssignment.remains.map(
                                        (item, index) => {
                                          const kelasName = item.class_title
                                          const getMataPelajaranLink = (
                                            role,
                                          ) => {
                                            return role === 'administrator' ||
                                              role === 'instruktur' ||
                                              role === 'adminprofile'
                                              ? `/kelas/${item.class_id}`
                                              : role === 'siswa'
                                              ? `/katalog-kelas/${item.class_id}`
                                              : ''
                                          }
                                          return (
                                            <tr key={index}>
                                              <td>{item.content_title}</td>
                                              <td>{item.section_title}</td>
                                              <td>{item.class_title}</td>
                                              <td>
                                                {item.komentar_wajib}
                                                {role_type ===
                                                'orangtua' ? null : (
                                                  <div className="overlay-edit">
                                                    <a
                                                      href={getMataPelajaranLink(
                                                        role_type,
                                                      )}
                                                      className="btn btn-icon btn-warning"
                                                      title={`Lihat ${kelasName}`}
                                                    >
                                                      <i className="fas fa-eye" />
                                                    </a>
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        },
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Modal>
                          </>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card widget-statstic-card">
                  <div className="card-body">
                    <div className="card-header-left mb-3">
                      <h5 className="mb-0">Feedback Tugas</h5>
                      <p className="p-t-10 m-b-0 text-warning">
                        Belum merespon feedback
                      </p>
                    </div>
                    <i className="feather icon-corner-up-right st-icon bg-warning"></i>
                    <div className="text-left d-flex justify-content-between">
                      <h3 className="d-inline-block m-0">
                        {feedbackAssignment
                          ? feedbackAssignment.countRemainsReply
                          : 0}
                      </h3>
                      {feedbackAssignment ? (
                        feedbackAssignment.countRemainsReply > 0 ? (
                          <>
                            <button
                              data-toggle="modal"
                              data-target="#assignmentFeedbackNotRespondedAktifitasBelajar"
                              className="btn btn-sm btn-outline-warning"
                            >
                              Detail
                            </button>
                            <div
                              className="modal fade"
                              id="assignmentFeedbackNotRespondedAktifitasBelajar"
                              data-backdrop="static"
                              data-keyboard="false"
                              tabIndex={-1}
                              role="dialog"
                              aria-labelledby="assignmentFeedbackNotRespondedAktifitasBelajar"
                              aria-hidden="true"
                            >
                              <div
                                className={`modal-dialog modal-dialog-centered`}
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Feedback tugas belum direspon
                                    </h5>
                                    <button
                                      type="button"
                                      className="close text-danger"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <i className="feather icon-x" />
                                    </button>
                                  </div>
                                  <div className="modal-body p-0">
                                    {feedbackAssignment.remainsReply.map(
                                      (item, index) => {
                                        let dd = moment(item.created_at)
                                          .add(30, 'days')
                                          .valueOf()
                                        let date =
                                          dd < moment().valueOf()
                                            ? moment(item.created_at).format(
                                                'DD MMM YYYY',
                                              )
                                            : moment(item.created_at).fromNow()

                                        function getMataPelajaranLink(role) {
                                          return role === 'administrator' ||
                                            role === 'instruktur' ||
                                            role === 'adminprofile'
                                            ? `/kelas/${item.class_id}`
                                            : role === 'siswa'
                                            ? `/katalog-kelas/${item.class_id}`
                                            : ''
                                        }

                                        return (
                                          <div
                                            key={index}
                                            className="col-12 pt-3 px-4 border-bottom"
                                            style={{ transition: '0.3s' }}
                                            onMouseEnter={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                '#f8f8f8')
                                            }
                                            onMouseLeave={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                'transparent')
                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                              <div className="">
                                                <p className="mb-0">
                                                  <span className="f-w-500 text-dark p-0">
                                                    {item.user_name}
                                                  </span>
                                                </p>
                                                <div className="d-flex">
                                                  <p
                                                    className="text-muted mr-2 mb-2"
                                                    style={{ fontSize: '10px' }}
                                                  >
                                                    <i className="feather icon-clock mr-1" />
                                                    {date}
                                                  </p>
                                                  {role_type === 'orangtua' ? (
                                                    <span
                                                      className="text-muted"
                                                      style={{
                                                        fontSize: '10px',
                                                      }}
                                                    >
                                                      <i className="feather icon-link mr-1" />
                                                      {item.class_title}/
                                                      {item.section_title}/
                                                      {item.content_title}
                                                    </span>
                                                  ) : (
                                                    <a
                                                      href={getMataPelajaranLink(
                                                        role_type,
                                                      )}
                                                      className="text-dark"
                                                      style={{
                                                        fontSize: '10px',
                                                      }}
                                                    >
                                                      <i className="feather icon-link mr-1" />
                                                      {item.class_title}/
                                                      {item.section_title}/
                                                      {item.content_title}
                                                    </a>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <p>{item.discuss}</p>
                                          </div>
                                        )
                                      },
                                    )}
                                  </div>
                                  <div className="modal-footer p-2">
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      Tutup
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card widget-statstic-card">
                  <div className="card-body">
                    <div className="card-header-left mb-3">
                      <h5 className="mb-0">Feedback Materi</h5>
                      <p className="p-t-10 m-b-0 text-primary">
                        Memberi feedback
                      </p>
                    </div>
                    <i className="fas fa-comment st-icon bg-primary"></i>
                    <div className="text-left">
                      <h3 className="d-inline-block m-0">
                        {countFeedbackSend}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card widget-statstic-card">
                  <div className="card-body">
                    <div className="card-header-left mb-3">
                      <h5 className="mb-0">Feedback Materi</h5>
                      <p className="p-t-10 m-b-0 text-danger">
                        Belum memberikan feedback
                      </p>
                    </div>
                    <i className="fas fa-comment-slash st-icon bg-danger"></i>
                    <div className="text-left d-flex justify-content-between">
                      <h3 className="d-inline-block m-0">
                        {countFeedbackRemaining}
                      </h3>
                      {countFeedbackRemaining > 0 ? (
                        <>
                          <button
                            data-toggle="modal"
                            data-target="#feedbackMateriDetail"
                            className="btn btn-sm btn-outline-danger"
                          >
                            Detail
                          </button>
                          <ModalFeedbackMateri items={dataFeedbackRemaining} />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="offset-md-3 offset-xl-0 col-md-6 col-xl-4">
                <div className="card widget-statstic-card">
                  <div className="card-body">
                    <div className="card-header-left mb-3">
                      <h5 className="mb-0">Durasi</h5>
                      <p className="p-t-10 m-b-0 text-warning">
                        Total waktu belajar
                      </p>
                    </div>
                    <i className="fas fa-clock st-icon bg-warning"></i>
                    <div className="text-left">
                      <h3 className="d-inline-block m-0">
                        {duration ? secondsToHms(duration) : '0'}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2 mb-2">
              <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'column',
                    },
                    title: {
                      text: 'Grup Kecil yang diikuti',
                    },
                    subtitle: {
                      text: '',
                    },
                    xAxis: {
                      categories: grafikGrupKecil
                        ? grafikGrupKecil.categories_ikuti_grup
                        : [],
                      crosshair: true,
                    },
                    yAxis: {
                      min: 0,
                      title: {
                        text: '',
                      },
                    },
                    tooltip: {
                      headerFormat:
                        '<span style="font-size:10px">{point.key}</span><table>',
                      pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                      footerFormat: '</table>',
                      shared: true,
                      useHTML: true,
                    },
                    plotOptions: {
                      column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                      },
                    },
                    series: grafikGrupKecil
                      ? grafikGrupKecil.series_ikuti_grup
                      : [],
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-xl-6 mt-3">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'column',
                    },
                    title: {
                      text: 'Balasan Grup Kecil',
                    },
                    subtitle: {
                      text: '',
                    },
                    xAxis: {
                      categories: grafikGrupKecil
                        ? grafikGrupKecil.categories_balasan_grup
                        : [],
                      crosshair: true,
                    },
                    yAxis: {
                      min: 0,
                      title: {
                        text: '',
                      },
                    },
                    tooltip: {
                      headerFormat:
                        '<span style="font-size:10px">{point.key}</span><table>',
                      pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                      footerFormat: '</table>',
                      shared: true,
                      useHTML: true,
                    },
                    plotOptions: {
                      column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                      },
                    },
                    series: grafikGrupKecil
                      ? grafikGrupKecil.series_balasan_grup
                      : [],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ModalFeedbackMateri = ({ items = [] }) => {
  return (
    <div
      className="modal fade"
      id="feedbackMateriDetail"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="feedbackMateriDetailLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5>Belum memberikan feedback materi</h5>
            <button
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="feather icon-x" />
            </button>
          </div>
          <div className="modal-body py-0 px-0">
            <div className="row mx-0 user-profile-list">
              {items.length !== 0 ? (
                <div className="table-responsive p-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Materi</th>
                        <th>Sub Mapel</th>
                        <th>Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => (
                        <tr key={index}>
                          <td>{item.content_title}</td>
                          <td>{item.sub_mata_pelajaran}</td>
                          <td>
                            <a
                              href={`/kelas/${item.id_sub_mata_pelajaran}`}
                              className="btn btn-icon btn-warning"
                              title={`Lihat ${item.sub_mata_pelajaran}`}
                            >
                              <i className="fas fa-eye" />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="col-12 mt-3 text-center">
                  <span
                    style={{ color: '#bbb' }}
                    className="f-16 f-w-500 mt-2"
                  >
                    Data tidak ditemukan
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ border: 'none' }}
            className="modal-footer p-1"
          >
            <button
              type="button"
              className="btn btn-outline-danger"
              data-dismiss="modal"
              aria-label="Close"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
