import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { useBaseContext } from 'context/base'
import BaseButton from 'component/button/BaseButton'
import useFile from 'hooks/useFile'
import Swal from 'sweetalert2'
import BaseButtonInformationModal from 'component/button/BaseButtonInformationModal'
import { listKeterangan } from 'data/rekapNilai'

export default function RekapNilaiV2({ dataKelas }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const { kelasId } = useParams()
  const { downloadFile } = useFile();

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [groups, setGroups] = useState([])
  const [refresh, setRefresh] = useState(true)
  const [selectedGroup, setSelectedGroup] = useState('')
  const [userData, setUserData] = useState([])
  const [isLoadingDownloadExcel, setIsLoadingDownloadExcel] = useState(false)

  /* ========================================= Functions ======================================== */
  async function handleRedirectContent(nilai) {
    const response = await getRequest(`content-detail?content_id=${nilai.content_id}`)
    const swalLoadingGetContent = Swal.fire({
      title: 'Harap tunggu sebentar...',
      html: `<div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
    })
    if (response) {
      if (response.data.length !== 0) {
        const content = response.data;
        Swal.close(swalLoadingGetContent)

        let routing = {
          url: '',
          data: { existingData: nilai, content }
        }
        switch (nilai.content_type_id) {
          case 6:
            routing.url = `/kelola-quiz/preview-quiz/${nilai.quiz_id}/answer-siswa/${nilai.id}`
            break;
          case 8:
            routing.url = `/kelas/${nilai.class_id}/content/${content.id}?tab=result`
            routing.data = { existingData: nilai,
              content: {
              ...content,
              urlQueryFetch: `?id=${content.id}&offset=0&limit=10&keyword=${nilai.name}&group_id=${null}`,
            }}
            break;
          case 7:
            routing.url = `/kelas/${nilai.class_id}/content/${content.id}?tab=result-survey`
            routing.data = { existingData: nilai,
              content: {
              ...content,
              urlQueryFetch: `?id=${content.id}&offset=0&limit=10&keyword=${nilai.name}&group_id=${null}`,
            }}
            break;
          default:
            routing.url = `/kelas/${nilai.class_id}/content/${content.id}?tab=result`
            routing.data = { existingData: nilai,
              content: {
              ...content,
              urlQueryFetch: `?id=${content.id}&offset=0&limit=10&keyword=${nilai.name}&group_id=${null}`,
            }}
            break;
        }

        history.push(routing.url, {
          existingData: nilai,
          content: {
            ...routing.data?.content,
            selectedStudent: nilai,
          },
        })
      }
    } else {
      Swal.close(swalLoadingGetContent)
    }
      
  }

  async function getGroups() {
    const response = await getRequest(`group-by-mapel?class_id=${kelasId}`)
    if (response) {
      if (response.data.length !== 0) {
        const groups = response.data.map((group) => ({
          value: group.id,
          label: group.group,
        }))
        groups.unshift({
          value: '',
          label: 'Semua Kelas'
        })
        setGroups(groups)
      }
    }
  }

  async function getScores(item) {
    setUserData([])
    if (item) {
      setSelectedGroup(item)

      cleanDatatable()
      const response = await getRequest(
        `rekap-nilai?class_id=${kelasId}&group_id=${item?.value}`,
      )

      if (response) {
        renderHeader(response.header)
        const users = initUsers(response.data, response.header)
        setUserData(response?.data)
        renderBody(users, item)
        setTimeout(() => {
          initDatatable()
        }, 500)
      }
    }
  }

  function renderHeader(header) {
    const headers = header.map((item) => {
      return {
        id: item.id,
        type_score: item.type_score,
        percentage: item.percentage,
        colspan: item.colspan !== 0 ? item.colspan : 1,
      }
    })
    headers.push({
      type_score: 'Nilai Akhir',
      colspan: 1
    })
    const headerTable = document.getElementById('scoreHeader')
    const headerTableIndex = document.getElementById('scoreHeaderIndex')
    headerTable.innerHTML = ``

    const title = document.createElement('th')
    const nim = document.createElement('th')
    title.innerText = 'Nama Siswa'
    nim.innerText = 'No. Induk'
    nim.rowSpan = 2
    title.rowSpan = 2
    nim.style = 'vertical-align:middle'
    title.style = 'vertical-align:middle'
    headerTable.append(title, nim)

    headers.forEach((item) => {
      const colHead = document.createElement('th')
      colHead.innerText = item.type_score
      colHead.colSpan = item.colspan !== 0 ? item.colspan : 1

      if (refresh) {
        if (item.colspan !== 0) {
          for (let a = 1; a <= item.colspan; a++) {
            const colHeadIndex = document.createElement('th')
            colHeadIndex.innerText = a
            headerTableIndex.append(colHeadIndex)
          }
        }
      }
      headerTable.append(colHead)
    })
    setRefresh(false)
  }

  function renderBody(users, selectedGroup) {
    const bodyTable = document.getElementById('scoreBody')
    bodyTable.innerHTML = ``

    users.forEach((item) => {
      const row = document.createElement('tr')
      const colName = document.createElement('td')
      const colNim = document.createElement('td')
      colName.innerHTML = `${item.name} ${item?.isTeacher ? '<span class="badge badge-warning badge-sm">Guru</span>' : ''}`
      colNim.innerText = `${item.nim}`
      row.append(colName, colNim)
      item.nilai.forEach((nilai) => {
        const col = document.createElement('td')
        const spanCol = document.createElement('span');

        spanCol.className = 'text-underline-hover cursor-pointer';
        spanCol.onclick = function() {
          handleRedirectContent({
            ...nilai,
            selectedGroup
          })
        }
        if (Number.isFinite(nilai.score)) {
          spanCol.innerText = nilai.score
        } else {
          if (nilai?.score === 'x') {
            spanCol.style = 'color: red;'
            spanCol.setAttribute('title', nilai.title)
          }

          if (nilai?.score == null) {
            spanCol.innerText = '-'
          } else {
            spanCol.innerText = nilai.score
          }
        }

        if (nilai.status) {
          col.style = 'font-weight:bold; background: rgb(255, 255, 200)'
          col.setAttribute('title', nilai.title + ' (Belum diperiksa)')
        } else {
          col.setAttribute('title', nilai.title)
        }
        col.append(spanCol)
        row.append(col)
      })

      // nilai akhir
      const colNilaiAkhir = document.createElement('td');
      colNilaiAkhir.setAttribute('title', 'Nilai Akhir');

      const spanColNilaiAkhir = document.createElement('span');
      if (Number.isFinite(item.nilai_akhir)) {
        // spanColNilaiAkhir.className = 'text-underline-hover cursor-pointer';
        spanColNilaiAkhir.innerText = item.nilai_akhir
        // spanColNilaiAkhir.onclick = function() {
        //   handleRedirectContent(item.nilai)
        // }
      } else {
        spanColNilaiAkhir.innerText = item.nilai_akhir
      }
      colNilaiAkhir.append(spanColNilaiAkhir)
      row.append(colNilaiAkhir);
      bodyTable.append(row)
    })
  }

  function initUsers(users, headers) {
    const initUser = users.map((user) => {
      let nilai = []
      user.type_score.forEach((userTypeScore) => {
        for (let header of headers) {
          if (header.id === userTypeScore.id) {
            if (header.colspan !== 0) {
              if (userTypeScore.nilai.length !== 0) {
                userTypeScore.nilai.forEach((userNilai) => {
                  const commonImportantData = {
                    ...user,
                    class_id: userNilai.class_id,
                    content_id: userNilai.id,
                    content_type: userNilai.tipe,
                    content_type_id: userNilai.content_type_id,
                    quiz_id: userNilai.quiz_id,
                    content_title: userNilai.title,
                  }

                  if (userNilai.is_evaluated) {
                    nilai.push({ // sudah dinilai
                      score: userNilai.total_score,
                      title: userNilai.title,
                      ...commonImportantData,
                    })
                  } else {
                    if (userNilai.is_done) { // sudah dikumpulkan
                      nilai.push({
                        score: null,
                        title: userNilai.title,
                        status: true,
                        ...commonImportantData,
                      })
                    } else {
                      nilai.push({ // belum mengumpulkan
                        score: null,
                        title: userNilai.title,
                        ...commonImportantData,
                      })
                    }
                  }
                })
              } else {
                for (let i = 0; i < header.colspan; i++) {
                  nilai.push({
                    score: '-',
                    title: '-',
                  })
                }
              }
            } else {
              nilai.push({
                score: 'Tidak ada materi',
                title: 'Tidak ada materi',
              })
            }
          }
        }
      })
      return {
        id: user.id,
        name: user.name,
        isTeacher: user.isTeacher,
        nim: user.nim,
        nilai: nilai,
        nilai_akhir: user.nilai_akhir
      }
    })
    return initUser
  }

  function appendScript(scriptToAppend, id) {
    const script = document.createElement('script')
    script.id = id
    script.src = scriptToAppend
    document.body.appendChild(script)
  }

  function cleanDatatable() {
    const header = document.getElementById('scoreHeader')
    if (header) {
      header.innerHTML = ``
      header.innerHTML = `
        <th>Nama Siswa</th>
        <th>No. Induk</th>
        <th>Nilai</th>
      `
    }
    const btnExport = document.querySelector('.dt-buttons')
    if (btnExport) {
      btnExport.remove()
    }
    for (let i = 0; i < 10; i++) {
      const el = document.getElementById(`rekap${i + 1}`)
      if (el) {
        el.remove()
      }
    }
  }

  function initDatatable() {
    appendScript('/admin/assets/js/plugins/jquery.dataTables.min.js', 'rekap1')
    appendScript('/admin/assets/js/plugins/dataTables.bootstrap4.min.js', 'rekap2')
    appendScript('/admin/assets/js/plugins/buttons.colVis.min.js', 'rekap3')
    appendScript('/admin/assets/js/plugins/jszip.min.js', 'rekap6')
    appendScript('/admin/assets/js/plugins/dataTables.buttons.min.js', 'rekap7')
    // appendScript('/admin/assets/js/plugins/buttons.html5.js', 'rekap8')
    appendScript('/admin/assets/js/plugins/buttons.bootstrap4.min.js', 'rekap9')
    // appendScript('/admin/assets/js/pages/data-export-custom.js', 'rekap10')
    // setTimeout(function () {
    //   const toolButtons = document.querySelectorAll(
    //     '.table-rekap-nilai .dt-buttons .btn',
    //   )
    //   toolButtons.forEach((el) => {
    //     if (el.classList.contains('buttons-copy')) {
    //       el.innerHTML = `<i class="fas fa-copy mr-1"></i>Copy`
    //     } else if (el.classList.contains('buttons-csv')) {
    //       el.innerHTML = `<i class="fas fa-file-csv mr-1"></i>CSV`
    //     } else if (el.classList.contains('buttons-excel')) {
    //       el.innerHTML = `<i class="fas fa-file-excel mr-1"></i>Excel`
    //     } else if (el.classList.contains('buttons-print')) {
    //       el.innerHTML = `<i class="fas fa-print mr-1"></i>Print`
    //     }
    //   })
    // }, 1000)
  }

  const downloadExcelHandler = async () => {
    setIsLoadingDownloadExcel(true)
    const response = await getRequest(`rekap-nilai-excel?class_id=${kelasId}&group_id=${selectedGroup?.value}`, true, { responseType: 'blob' })

    if (response) {
      setIsLoadingDownloadExcel(false)
      downloadFile({ blobData: response, fileName: `LMS - ${dataKelas?.title}.xlsx` })
    } else {
      setIsLoadingDownloadExcel(false)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getGroups()
    }

    return () => {
      isSubscribed = false
      cleanDatatable()
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <div className="card">
      <div className="card-body">
        <h5>Pilih Kelas: </h5>
        {/* <div className='mb-2 d-flex justify-content-between flex-wrap align-items-center'>
          <div>
            <h5>Pilih Kelas: </h5>
          </div>
          <div>
            <BaseButtonInformationModal />
          </div>
        </div> */}
        <Select
          placeholder="Kelas"
          options={groups}
          onChange={getScores}
          className="mb-3"
        />
        <div className="dt-responsive table-responsive table-rekap-nilai">
          <div className='mb-2 d-flex justify-content-between flex-wrap align-items-center'>
            <div>
              <BaseButton props={{ className: 'mb-3' }} onClick={downloadExcelHandler} isLoading={isLoadingDownloadExcel} isDisabled={!userData.length}>
                <i className="fas fa-file-excel mr-1" />
                Excel
              </BaseButton>
            </div>
            <div>
              <BaseButtonInformationModal
                list={listKeterangan}
              />
            </div>
          </div> 
          <table
            id="excel-bg"
            className="table table-bordered nowrap text-center"
          >
            <thead>
              <tr id="scoreHeader">
                <th>Nama Siswa</th>
                <th>No. Induk</th>
                <th>Nilai</th>
              </tr>
              <tr id="scoreHeaderIndex"></tr>
            </thead>
            <tbody id="scoreBody">
              <tr>
                <td
                  className="text-center"
                  colSpan="100%"
                >
                  Data tidak ditemukan
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
