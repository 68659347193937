import React, { useEffect, useState } from 'react'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container } from 'component/template'
import DetailProfile from './detail-profile'
import Overview from 'pages/aktivitas-siswa/infografik/overview'
import WaktuBelajar from 'pages/aktivitas-siswa/infografik/waktu-belajar'
import LoadingSpinner from 'component/loading'
import ChangePassword from './change-password'
import ModalChangeAvatar from './modal-change-avatar'
import { avatarDefaultUser } from 'util/constant'

const profileDpStyle = {
  width: '100px',
  height: '100px',
  position: 'relative',
  marginBottom: '5px',
  padding: '0',
  overflow: 'unset',
}

const avatarStyle = {
  width: '100px',
  height: '100px',
  objectFit: 'cover',
}

export default function Profile() {
  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [profileData, setProfileData]     = useState(null)
  const [roleActive, setRoleActive]       = useState('')
  const [roleType, setRoleType]           = useState('')
  const [dataSiswa, setDataSiswa]         = useState({})
  const [defaultAvatar, setDefaultAvatar] = useState(false)

  /* ========================================= Functions ======================================== */
  async function getAllSiswa() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      const response = await getRequest(
        `siswa-all?siswa_id=${user.id}&limit=1&offset=0&search=`,
      )
      if (response) {
        setDataSiswa(response.data[0])
      }
    }
  }

  async function getProfile() {
    await getRequest('profile-user')
      .then((response) => {
        setProfileData(response.data)
      })
      .catch((error) => {
        console.error('ERROR', error)
      })

    let roleActive = JSON.parse(localStorage.getItem('role_active'))
    if (roleActive) {
      setRoleActive(roleActive.role)
      setRoleType(roleActive.role_type)
    }
  }

  /* ======================================== Components ======================================== */
  const ListRole = ({ item }) => {
    const IconRole = ({ type }) => {
      if (type == 'super') {
        return <i className="fas fa-user-cog text-dark f-24"></i>
      } else if (type == 'administrator' || type == 'adminprofile') {
        return <i className="fas fa-user-tie text-dark f-24"></i>
      } else if (type == 'instruktur') {
        return <i className="fas fa-chalkboard-teacher text-dark f-24"></i>
      } else if (type == 'siswa') {
        return <i className="fas fa-user-graduate text-dark f-24"></i>
      } else {
        return <i className="fas fa-user text-dark f-24" />
      }
    }

    return (
      <tr>
        <td>
          <IconRole type={item.role.role_type} />
        </td>
        <td>{item.role.role === 'Instruktur' ? 'Guru' : item.role.role}</td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getProfile()
    getAllSiswa()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      {profileData && (
        <Container>
          <div className="user-profile user-card mb-4">
            <div className="card-body py-0">
              <div className="user-about-block m-0">
                <div className="row">
                  <div className="col-md-4 text-center mt-n5">
                    <div className="change-profile text-center">
                      <div className="dropdown w-auto d-inline-block">
                        <div
                          style={profileDpStyle}
                          className="profile-dp"
                        >
                          <div className="position-relative d-inline-block">
                            {defaultAvatar ? (
                              <img
                                style={avatarStyle}
                                className="img-radius img-fluid"
                                src={avatarDefaultUser}
                                alt={profileData.name}
                              />
                            ) : (
                              <img
                                style={avatarStyle}
                                className="img-radius img-fluid"
                                src={profileData.linkAvatar}
                                alt={profileData.name}
                                onError={() => setDefaultAvatar(true)}
                              />
                            )}
                          </div>
                          {profileData?.profile_id !== '04acc544-3fcf-47cc-a740-ab02024e1ea8' ? (<div className="overlay">
                            <span
                              data-toggle="modal"
                              data-target="#staticChangeAvatar"
                            >
                              Ubah
                            </span>
                          </div>) : null}
                        </div>
                      </div>
                    </div>
                    <h5 className="mb-1">{profileData.name}</h5>
                    <p className="mb-2 text-muted">
                      {roleActive ? roleActive : '-'}
                    </p>
                  </div>
                  <div className="col-md-8 mt-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <a className="mb-1 text-muted d-flex align-items-end text-h-primary">
                          <i className="feather icon-mail mr-2 f-18" />
                          {profileData.email}
                        </a>
                        <a className="mb-1 text-muted d-flex align-items-end text-h-primary">
                          <i className="feather icon-phone mr-2 f-18" />
                          {profileData.phone}
                        </a>
                      </div>
                      <div className="col-md-6">
                        <div className="media">
                          <i className="feather icon-map-pin mr-2 mt-1 f-18" />
                          <div className="media-body mt-1">
                            <p className="mb-0 text-muted">
                              {profileData.location
                                ? profileData.location
                                : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul
                      className="nav nav-tabs profile-tabs nav-fill"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link text-reset active"
                          id="profile-tab"
                          data-toggle="tab"
                          href="#profile"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          <i className="feather icon-user mr-2" />
                          Detail Informasi
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link text-reset"
                          id="password-tab"
                          data-toggle="tab"
                          href="#password"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          <i className="feather icon-lock mr-2" />
                          Ubah Kata Sandi
                        </a>
                      </li>
                      {roleType == 'siswa' ? (
                        <li className="nav-item">
                          <a
                            className="nav-link text-reset"
                            id="infografik-tab"
                            data-toggle="tab"
                            href="#infografik"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false"
                          >
                            <i className="feather icon-bar-chart mr-2" />
                            Infografik
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* profile header end */}
          {/* profile body start */}
          <div className="row">
            <div className="col-md-8 order-md-2">
              <div
                className="tab-content"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <DetailProfile
                    data={profileData}
                    getProfile={getProfile}
                    roleType={roleType}
                    defaultSignature={profileData.linkSignature}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="password"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <ChangePassword />
                </div>
                <div
                  className="tab-pane fade"
                  id="infografik"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <Overview siswa={dataSiswa} />
                  <WaktuBelajar siswa={dataSiswa} />
                </div>
              </div>
            </div>
            <div className="col-md-4 order-md-1">
              <div className="card table-card">
                <div className="card-header borderless">
                  <h5>Role Akses</h5>
                </div>
                <div className="card-body px-0 py-0">
                  <div className="table-responsive">
                    <div
                      className="revenue-scroll"
                      style={{ height: '310px', position: 'relative' }}
                    >
                      <table className="table table-hover mb-0">
                        <tbody>
                          {profileData.roletousers ? (
                            profileData.roletousers.map((item, idx) => (
                              <ListRole
                                item={item}
                                key={idx}
                              />
                            ))
                          ) : (
                            <LoadingSpinner />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* profile body end */}
          {/* Modal Change Avatar */}
          <ModalChangeAvatar
            defaultAvatar={profileData.linkAvatar}
            id={profileData.id}
            getProfile={getProfile}
            profileData={profileData}
          />
        </Container>
      )}
    </TemplateAdmin>
  )
}
