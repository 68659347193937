import React, { useState } from 'react'
import useContentPlayerContext from 'context/content-player'
import useQuizEval from 'context/quiz-eval'
import QuizEvalItem from './quiz-eval-item'
import QuizEvalModal from './quiz-eval-modal'
import QuizEvalAlert from './quiz-eval-alert'
import QuizEvalGroupPicker from './quiz-eval-group-picker'
import RejectModal from 'component/modal/RejectModal'
import RejectModalWithRBModalComponent from 'component/modal/RejectModalWithRBModalComponent'

export default function QuizEval() {
  /* ====================================== Consume Context ===================================== */
  const { siswa, quiz } = useQuizEval()
  const { content } = useContentPlayerContext()

  /* --------------------------------- STATES --------------------------------- */
  const [isShowModalReject, setIsShowModalReject] = useState(false)
  
  /* ========================================== Output ========================================== */
  return (
    <div className="user-profile-list">
      <QuizEvalGroupPicker />
      <div className="dt-responsive table-responsive">
        <QuizEvalAlert />
        <table
          id="tables"
          className="table table-bordered nowrap rounded"
        >
          <thead>
            <tr>
              <th>No. Induk</th>
              <th>Nama</th>
              <th>Email</th>
              <th>Nilai</th>
              <th>Status</th>
              <th>Actions</th>
              {(content?.feedback || content?.group_status) && (
                <th>Prasyarat</th>
              )}
            </tr>
          </thead>
          <tbody>
            {siswa.data.length ? (
              siswa.data.map((siswa, index) => (
                <QuizEvalItem
                  item={siswa}
                  key={index}
                  setIsShowModalReject={setIsShowModalReject}
                />
              ))
            ) : (
              <tr>
                <td
                  colSpan={6}
                  style={{ textAlign: 'center' }}
                >
                  <span>Data siswa tidak ditemukan.</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <QuizEvalModal />

        <RejectModalWithRBModalComponent show={isShowModalReject} item={quiz.detail.data} title='tugas' onReject={quiz.handle.delete} isWithCloseButton={false} isWithXButton={false} isWithHeader={false} handleClose={() => setIsShowModalReject(false)} />
      </div>
    </div>
  )
}
