import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'

export default function GraphicOrganizer() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, deleteRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [limit, setLimit]         = useState(10)
  const [offset, setOffset]       = useState(0)
  const [search, setSearch]       = useState('')
  const [listData, setListData]   = useState([])
  const [totalRows, setTotalRows] = useState(0)

  /* ========================================= Functions ======================================== */
  async function getAllData() {
    const response = await getRequest(
      `graphors?limit=${limit}&offset=${offset}&search=${search}`,
    )
    if (response) {
      setListData(response.data)
      setTotalRows(response.totalRow)
    }
  }

  /* ======================================== Components ======================================== */
  const RenderItem = ({ item }) => {
    function handleEdit(item) {
      if (item.graphor_type == 1) {
        history.push(`graph-sort-edit/${item.id}`)
      }
      if (item.graphor_type == 2) {
        history.push(`graph-group-edit/${item.id}`)
      }
    }

    function handleDelete(item) {
      Swal.fire({
        title: 'Apa anda yakin?',
        text: `Anda akan menghapus "${item.description}"!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await deleteRequest(`graphors?id=${item.id}`)
          if (response) {
            getAllData()
            Swal.fire('Berhasil!', 'Data berhasil dihapus.', 'success')
          } else {
            Swal.fire(
              'Oppsss!',
              'Terjadi kesalahan coba beberapa saat lagi.',
              'error',
            )
          }
        }
      })
    }

    function handleEvaluasi(item) {
      history.push(`/graphic-organizer/eval/${item.id}`)
    }

    return (
      <tr>
        <td>
          {item.description ? (
            item.description
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          {item.type === 'sorting' && <i className="fas fa-list-ol mr-1" />}
          {item.type === 'group' && <i className="fas fa-columns mr-1" />}
          {item.type ? (
            item.type.toUpperCase()
          ) : (
            <i className="feather icon-minus" />
          )}
        </td>
        <td>
          {item.active ? (
            <span className="badge badge-success">Aktif</span>
          ) : (
            <span className="badge badge-secondary">Tidak Aktif</span>
          )}
        </td>
        <td>
          <button
            onClick={() => {
              handleEdit(item)
            }}
            className="btn btn-sm btn-success mr-2"
          >
            <i className="feather icon-edit mr-1" />
            <span>Edit</span>
          </button>
          <button
            onClick={() => {
              handleEvaluasi(item)
            }}
            className="btn btn-sm btn-info mr-2"
          >
            <i className="feather icon-check-square mr-1" />
            <span>Evaluasi</span>
          </button>
          <button
            onClick={() => {
              handleDelete(item)
            }}
            className="btn btn-sm btn-danger"
          >
            <i className="feather icon-trash-2 mr-1" />
            <span>Hapus</span>
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getAllData()
    }

    return () => {
      isSubscribed = false
    }
  }, [limit, offset, search])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Graphic Organizer" />
        
        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list  ">
              <div className="card-header d-flex justify-content-between">
                <div className="btn-group mr-2">
                  <button
                    className="btn  btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="feather icon-plus-circle mr-1" />
                    <span>Tambah</span>
                  </button>
                  <div className="dropdown-menu">
                    <Link
                      className="dropdown-item"
                      to="/graph-sort-add"
                    >
                      <i className="fas fa-list-ol mr-1" />
                      <span>Sorting</span>
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/graph-group-add"
                    >
                      <i className="fas fa-columns mr-1" />
                      <span>Grouping</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body dt-responsive table-responsive">
                <DataTable
                  className={'table table-striped table-bordered nowrap'}
                  data={listData}
                  totalRows={totalRows}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => {
                    setSearch(val)
                  }}
                  onChangeLimit={(val) => {
                    setLimit(val)
                  }}
                  onChangePage={(val) => {
                    setOffset(val)
                  }}
                  column={['Nama', 'Jenis', 'Status', 'Actions']}
                  renderItem={(list, idx) => (
                    <RenderItem
                      index={idx}
                      item={list}
                      key={idx}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}
