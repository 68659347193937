import React, { useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import InvalidText from 'component/invalid/text'

const FormEditSurvey = () => {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const location = useLocation()
  const params   = useParams()
  const surveyId = params.surveyId
  const existingSurvey = location.state.existingData

  /* ====================================== Consume Context ===================================== */
  const { putRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const links = [{ url: '/survey-engine', name: 'Kelola Survey' }]

  /* ======================================= Local States ======================================= */
  const [title, setTitle]           = useState(existingSurvey?.title || '')
  const [surveyCode, setSurveyCode] = useState(existingSurvey?.surveycode || '')
  const [status, setStatus]         = useState(existingSurvey.active ? 1 : 0)

    // FORM VALIDATION STATE
  const [isTitleInvalid, setIsTitleInvalid]             = useState(false)
  const [isSurveyCodeInvalid, setIsSurveyCodeInvalid]   = useState(false)
  const [isSurveyCodeTooShort, setIsSurveyCodeTooShort] = useState(false)
  const [isSurveyCodeSpecial, setIsSurveyCodeSpecial]   = useState(false)

  /* =========================================== Refs =========================================== */
  const refTitle      = useRef()
  const refSurveyCode = useRef()

  /* ========================================= Functions ======================================== */
  function handleChangeTitle(event) {
    setTitle(event.target.value)
  }

  function handleChangeStatus(event) {
    switch (event.target.checked) {
      case true:
        setStatus(1)
        break
      case false:
        setStatus(0)
        break
      default:
        break
    }
  }

  function handleChangeSurveyCode(event) {
    setSurveyCode(event.target.value.toUpperCase())
  }

  function checkSpecialChars(string) {
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return specialChars.test(string) //return true when contains special chars
  }

  function handleFormValidation() {
    if (
      title &&
      surveyCode.length === 5 &&
      checkSpecialChars(surveyCode) === false
    ) {
      setIsTitleInvalid(false)
      setIsSurveyCodeInvalid(false)
      setIsSurveyCodeTooShort(false)
      setIsSurveyCodeSpecial(false)
      handleFormSubmit(surveyId, title, surveyCode, status)
    } else {
      if (!surveyCode) {
        setIsSurveyCodeInvalid(true)
        refSurveyCode.current.focus()
      } else {
        setIsSurveyCodeInvalid(false)
      }

      if (surveyCode.length !== 5) {
        setIsSurveyCodeTooShort(true)
        refSurveyCode.current.focus()
      } else {
        setIsSurveyCodeTooShort(false)
      }

      if (checkSpecialChars(surveyCode)) {
        setIsSurveyCodeSpecial(true)
        refSurveyCode.current.focus()
      } else {
        setIsSurveyCodeSpecial(false)
      }

      if (!title) {
        setIsTitleInvalid(true)
        refTitle.current.focus()
      } else {
        setIsTitleInvalid(false)
      }

      window.notification('Data belum lengkap', 'Mohon lengkapi data')
    }
  }

  async function handleFormSubmit(survey_id, title, surveycode, active) {
    let form = new FormData()
    form.append('id', survey_id)
    form.append('title', title)
    form.append('surveycode', surveycode)
    form.append('active', active)

    const response = await putRequest('survey', form)
    if (response) {
      window.notification('Sukses', 'Survey berhasil diubah', 'success')
      history.goBack()
    } else {
      console.error('ERROR', response)
      window.notification(
        'Terjadi kesalahan',
        'Coba beberapa saat lagi',
        'error',
      )
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header
          pageName="Edit Survey"
          links={links}
        />

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h5 className="modal-title">
                  <i className="fas fa-check-double mr-1" />
                  Informasi Survey
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <form>
                      {/* Input Title Survey */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="title-survey"
                        >
                          <span className="mr-1">Nama Survey</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={title}
                            onChange={(event) => handleChangeTitle(event)}
                            ref={refTitle}
                            type="text"
                            className={`${
                              isTitleInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="title-survey"
                            placeholder="Judul survey"
                          />
                          {isTitleInvalid ? (
                            <InvalidText name={'Nama'} />
                          ) : null}
                        </div>
                      </div>

                      {/* Input Survey Code */}
                      <div className="form-group mb-5 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="kode-survey"
                        >
                          <span className="mr-1">Kode survey</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            value={surveyCode}
                            maxLength={5}
                            onChange={(event) => handleChangeSurveyCode(event)}
                            ref={refSurveyCode}
                            type="text"
                            className={`${
                              isSurveyCodeInvalid ? 'is-invalid' : ''
                            } form-control`}
                            id="kode-survey"
                            placeholder="5 karakter kombinasi huruf/angka"
                          />
                          {isSurveyCodeInvalid ? (
                            <InvalidText name={'Kode survey'} />
                          ) : null}
                          {isSurveyCodeTooShort ? (
                            <span className="col small form-text text-danger">
                              Kode survey minimal 5 karakter.
                            </span>
                          ) : null}
                          {isSurveyCodeSpecial ? (
                            <span className="col small form-text text-danger">
                              Kode survey hanya berisi kombinasi huruf/angka.
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {/* Select Survey Status */}
                      <div className="form-group mb-3 row">
                        <label
                          className="col-form-label col-sm-3"
                          htmlFor="status-survey"
                        >
                          <span className="mr-1">Status</span>
                        </label>
                        <div className="form-group col-sm-9">
                          <div className="switch switch-primary d-inline m-r-10">
                            <input
                              onChange={(event) => handleChangeStatus(event)}
                              checked={status === 1}
                              type="checkbox"
                              id="switch-status-survey"
                            />
                            <label
                              htmlFor="switch-status-survey"
                              className="cr"
                            ></label>
                          </div>
                          <label>
                            {status === 1 ? 'Aktif' : 'Tidak Aktif'}
                          </label>
                        </div>
                      </div>

                      {/* Form keterangan */}
                      <div className="form-group d-flex">
                        <label className="text-muted mr-2">Keterangan:</label>
                        <span className="text-danger mr-1">*</span>
                        <label className="text-muted">Wajib diisi</label>
                      </div>

                      {/* Form button */}
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-outline-danger mr-2"
                          onClick={() => history.goBack()}
                        >
                          Kembali
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => handleFormValidation()}
                        >
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}

export default FormEditSurvey
