import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'

import { useBaseContext } from 'context/base'
import DataTableWithFilter from 'component/data-table/indexWithFilter'
import { Back } from 'component/button'
import { timeConvert } from 'util/global'

export default function ReportSiswaSubMataPelajaran() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const param = useParams()

  /* =========================================== Refs =========================================== */
  const mountedRef = useRef(true)

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()

  /* =================================== Consume Local Storage ================================== */
  const { role_type } = JSON.parse(localStorage.getItem('role_active'))

  /* ======================================= Local States ======================================= */
  const [coursesSiswa, setCoursesSiswa] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')
  const [optionsSemester, setOptionsSemester] = useState([])
  const [selectedOptionSemester, setSelectedOptionSemester] = useState({})
  const [isLoadingOptionsSemester, setIsLoadingOptionsSemester] = useState(false)

  /* ========================================= Functions ======================================== */

  /**
   * Add Query Semester
   * 
   * @param {string} url 
   */
  function addQuerySemester(url) {
    let data = url.substring() // copy string to manipulate

    if (selectedOptionSemester?.value !== 'all') {
      data += `&semester=${selectedOptionSemester?.value}`
    }

    return data
  }

  async function getListSemester() {
    setIsLoadingOptionsSemester(true)
    const response = await getRequest(
      `semester`,
    )
    if (response) {
      const data = response?.data

      if (Array.isArray(data) && data?.length) {
        const manipulatedDataToOptionsReactSelect = data?.map(d => {
          const semesterActive = d?.status

          if (semesterActive) {
            const optionReactSelect = {
              label: `${d?.tahun_mulai}/${d?.tahun_selesai} - ${d?.title_render} (Aktif)`,
              value: d?.code,
            }

            setSelectedOptionSemester(optionReactSelect)
            return optionReactSelect
          } else {
            return {
              label: `${d?.tahun_mulai}/${d?.tahun_selesai} - ${d?.title_render}`,
              value: d?.code,
            }
          }
        })

        const semesterListOption = [{ label: 'Semua', value: 'all' }].concat(manipulatedDataToOptionsReactSelect)

        setOptionsSemester(semesterListOption)
      }

      setIsLoadingOptionsSemester(false)
    } else {
      setIsLoadingOptionsSemester(false)
    }
  }

  async function getCoursesSiswa() {
    if (mountedRef.current) {
      const url = addQuerySemester(`classBySiswa?id=${param.siswaId}&limit=${limit}&offset=${offset}&search=${search}`)
      const response = await getRequest(url)
      if (response) {
        setCoursesSiswa(response.data)
        setTotalRecords(response.totalRecords)
      }
    }
  }

  function handleConvertDateMoment(item) {
    let date = moment(item).add(30, 'days').valueOf()
    let resultDateMoment =
      date < moment().valueOf()
        ? moment(item).format('DD MMM YYYY')
        : moment(item).fromNow()

    return resultDateMoment
  }

  /* ======================================== Components ======================================== */
  const RenderItem = ({ item, index }) => {
    const [path, setPath] = useState('/')
    const progress = item.progress_percent || 0

    function checkRole() {
      if (mountedRef.current) {
        if (role_type === 'orangtua') {
          setPath(`/siswa/${param.siswaId}/mapel/${item.id}`)
        } else if (role_type === 'siswa') {
          setPath(`/progress-siswa/kelas/${item.id}`)
        } else if (
          role_type === 'administrator' ||
          role_type === 'instruktur' ||
          role_type === 'adminprofile'
        ) {
          setPath(
            `/laporan-aktifitas-siswa-progress/${param.siswaId}/${item.id}`,
          )
        }
      }
    }

    function handleDetail() {
      history.push(path)
    }

    useEffect(() => {
      checkRole()
    }, [item.id])
    
    return (
      <tr>
        <td>{item.title}</td>
        <td title={`Progress ${progress} %`}>
          {progress === 0 ? (
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `100%`, backgroundColor: '#ecf0f5' }}
              aria-valuenow={`100%`}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span
                className="text-dark"
                style={{ fontSize: '12px', fontWeight: 'bold' }}
              >
                {progress}%
              </span>
            </div>
          ) : (
            <>
              {progress === 100 ? (
                <div
                  className="progress"
                  style={{ height: '20px' }}
                >
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                  >
                    <span style={{ fontSize: '12px', fontWeight: '700' }}>
                      {progress} %
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="progress"
                  style={{ height: '20px' }}
                >
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                  >
                    <span style={{ fontSize: '12px', fontWeight: '700' }}>
                      {progress && `${progress}%`}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </td>
        <td>
          {item.terdaftar == null ? (
            <i className="feather icon-minus" />
          ) : (
            handleConvertDateMoment(item.terdaftar)
          )}
        </td>
        <td>
          {item.selesai == null ? (
            <i className="feather icon-minus" />
          ) : (
            handleConvertDateMoment(item.selesai)
          )}
        </td>
        <td>
          {item.waktu_penyelesaian == null ? (
            <i className="feather icon-minus" />
          ) : (
            timeConvert(item.waktu_penyelesaian)
          )}
        </td>
        <td>
          <button
            onClick={handleDetail}
            className="btn btn-sm btn-info"
          >
            <i className="fas fa-eye mr-1" />
            Detail Progress
          </button>
        </td>
      </tr>
    )
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    if (selectedOptionSemester?.value) {
      getCoursesSiswa()
    }
  }, [limit, offset, search, selectedOptionSemester])

  useEffect(() => {
    if (mountedRef.current) {
      getListSemester()
    }
    return () => {
      mountedRef.current = false
    }
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card user-profile-list">
            <div className="card-body">
              <div className="dt-responsive table-responsive">
                <DataTableWithFilter
                  className={'table table-striped table-bordered nowrap'}
                  data={coursesSiswa}
                  totalRows={totalRecords}
                  show={[10, 20, 30, 40, 50]}
                  onChangeSearch={(val) => {
                    setSearch(val)
                  }}
                  onChangeLimit={(val) => {
                    setLimit(val)
                  }}
                  onChangePage={(val) => {
                    setOffset(val)
                  }}
                  column={[
                    'sub mata pelajaran',
                    'progress',
                    'mulai',
                    'selesai',
                    'waktu pengerjaan',
                    'actions',
                  ]}
                  renderItem={(item, i) => (
                    <RenderItem
                      item={item}
                      index={i}
                      key={item.id}
                    />
                  )}
                  filter1={{
                    isDisabled: isLoadingOptionsSemester,
                    placeholder: "Filter Semester...",
                    options: optionsSemester,
                    // defaultValue: selectedOptionSemester,
                    onChange: setSelectedOptionSemester
                  }}
                />
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col p-2 text-right">
                  <Back />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
